import React from "react";
import card1 from "../../../assets/img/card1.png";
import card2 from "../../../assets/img/card2.png";
import card3 from "../../../assets/img/card3.png";
import card4 from "../../../assets/img/card4.png";
import card5 from "../../../assets/img/card5.png";
import StripeForm from "../Stripe/StripeForm";
import OrderSummary from "./OrderSummary";

function StepTwo(props) {
	const { StepTwoContinue, handleNext, CheckOutData, setCardToken, cardToken } =
		props;

	return (
    <>
      <h2 className="font-40 fw-600 text-center pb-5">Payment Details</h2>
      <div className="row">
        <div className="col-md-7">
          <div className="setpper-step-container">
            <div className="paymentInfo">
              <div className="head">
                <h4 className="font-20 fw-600">Enter Your Payment Details</h4>
              </div>
              <StripeForm StepTwoContinue={StepTwoContinue} handleNext={handleNext} setCardToken={setCardToken} cardToken={cardToken} />
            </div>
          </div>
        </div>
        <OrderSummary CheckOutData={CheckOutData} />
      </div>
    </>
  );
}

export default StepTwo;
