const OrderSummary = ({ CheckOutData }) => {
  return (
    <div className="col-md-5 setpper-step-container">
      <div className="OrderSummary">
        <div className="headingg">
          <h3 className="font-25 pb-3">Order Summery</h3>
        </div>
        <div className="order_info">
          <div className="itemTotal order-flex">
            <div className="property">
              <h5 className="fw-500">Item Total:</h5>
            </div>
            <div className="value">
              <h5 className="fw-300">£{Number(CheckOutData?.subTotal).toFixed(2)}</h5>
            </div>
          </div>
          <div className="shipmentDelivery order-flex">
            <div className="property">
              <h5 className="fw-500">Shipment & Delivery:</h5>
            </div>
            <div className="value">
              <h5 className="fw-300">£0</h5>
            </div>
          </div>
          <div className="promoApplied order-flex">
            <div className="property">
              <h5 className="fw-500">Promo Applied:</h5>
            </div>
            <div className="value">
              <h5 className="fw-300">£{CheckOutData?.couponData?.discount ? Number(CheckOutData?.couponData?.discount).toFixed(2) : "0"}</h5>
            </div>
          </div>
        </div>
        <div className="orderTotal">
          <div className="property">
            <h5 className="fw-700">Order Total</h5>
          </div>
          <div className="value">
            <h5 className="fw-400">£{CheckOutData?.total}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;