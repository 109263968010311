import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import SellerHeader from "../../components/SellerHeader";
import "../../../assets/css/productinventory.css";
import sellerphone from "../../../assets/img/sellerphone.png";
import Footer from "../../components/Footer";
import SellerSideBar from "./SellerSideBar";
import { useDispatch, useSelector } from "react-redux";
import { GetAllOrders } from "../../../network/SellerNetwork";
import { OrdersData } from "../../../redux/actions/SellerAction";
import { Switch } from "@mui/material";
import ReactPaginate from "react-paginate";
import SellerFooter from "../../components/SellerFooter";

const MyOrder = () => {
  let { search, page, order } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(page ?? 1);
  const [searchQuery, setSearchQuery] = useState(search ?? null);
  const [arrangement, setArrangement] = useState(order ?? null);
  const [orders, setOrders] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  
  // Get All orders
  useEffect(() => {
    if (currentPage !== undefined) {
      GetAllOrders(currentPage, token, searchQuery, arrangement)
        .then((res) => {
          let tempOrders = res?.data?.data?.orders ?? [];
          tempOrders = tempOrders.map((o)=>{
            return {
              ...o,
              orderImage: o.details[0].product.thumbnail_url
            }
          })
          setOrders(tempOrders);
          const total = res?.data?.data?.total_size;
          setPageCount(Math.ceil(total / 10));
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentPage, searchQuery, arrangement]);





  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  return (
    <>
      <div className="seller_prodinv_page myorder_page">
        <SellerHeader />
        <div className="container-fluid">
          <div className="seller_prodinv_sec">
            <div>
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <div className="totalcard">
                  <div className="form-head">
                    <div className="seller_head">
                      <div className="">
                        <h3>My Orders</h3>
                      </div>
                      <div className="search_sec">
                        <div className="searchandsort form-group">
                          <input
                            type="search"
                            name="search"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                            }}
                            placeholder="Search"
                            className="form-control"
                          />
                        </div>
                        <div className="searchandsort form-group">
                          <input
                            type="search"
                            name="search"
                            value={arrangement}
                            onChange={(e) => {
                              setArrangement(e.target.value);
                            }}
                            placeholder="Sort By   |  Asc/ Desc"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-table-wrapper table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className="row1">
                          <th className="order-no">Order ID</th>
                          <th className="order-date">Order Type</th>
                          <th className="order-status">Qty</th>
                          <th className="order-total">Amount</th>
                          <th className="details">Status</th>
                          <th className="details">ACTION</th>
                        </tr>
                        {orders &&
                          orders.map((order, index) => {
                            var productStocks = order.details
                              .map((detail) => {
                                return detail.qty;
                              })
                              .join(", ");
                            var productPrice = order.details
                              .map((detail) => {
                                return detail.price;
                              })
                              .join(", $");
                            return (
                              <tr className="row2" key={index}>
                                <td className="pname">
                                  <input type="checkbox" name="" value="" />
                                  <div className="prodinv_phone">
                                    <img src={order.orderImage} className="img-fluid" alt="" />
                                  </div>
                                  ID #{order.id}
                                </td>
                                <td className="ordertype">Trade Order</td>
                                <td className="pstock">{productStocks}</td>
                                <td className="pprice">${productPrice}</td>
                                <td className="">
                                  <StatusSpan status={order.order_status} />
                                </td>
                                <td className="actions">
                                  <div className="seller_actions">
                                    <span onClick={() => navigate("/seller/order-detail/" + order.id)}>View Detail</span>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container mt-5">
                <ReactPaginate previousLabel="<" nextLabel=" >" breakLabel={"..."} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handlePageClick} containerClassName={"pagination justify-content-center"} pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"} previousLinkClassName={"page-link"} nextClassName={"page-item"} nextLinkClassName={"page-link"} breakClassName={"page-item"} breakLinkClassName={"page-link"} activeClassName={"active"} />
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

const StatusSpan = ({ status = null }) => {
  switch (status) {
    case "completed":
      return <button className="active">{status}</button>;
    case "delivered":
      return <button className="active">{status}</button>;
    case "pending":
      return <button className="inactive">{status}</button>;
    case "cancelled":
      return <button className="inactive">{status}</button>;
    case "shipping":
      return <button className="shipping">{status}</button>;
    default:
      return <button className="inactive">{status}</button>;
  }
};

export default MyOrder;
