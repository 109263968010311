import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { TextAreaWrapper } from "../../../Form/Element";
import * as Yup from "yup";
import { PostReply } from "../../../network/Network";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function SingleReviewCard(props) {
  const { item, refreshReviews, seller = false } = props;
  const initialReplyForm = {
    review_id: item.id,
    comment: "",
    parent_id: 0,
    active: false,
  };
  const [formActive, setFormActive] = useState(false);

  return (
    <>
      <div className="mainReview">
        <div className="brieflyReview">
          <div className="img-box">
            <figure className="image-review">{item?.customer?.profile_url == null ? <img src={`${ramdomImage(`${item?.customer?.full_name}}`)}`} alt="" className="image-fluid image-width" /> : <img src={item?.customer?.profile_url} alt="" className="image-fluid image-width" />}</figure>
          </div>
          <div className="contentDv border-2 border-black">
            <div className="info">
              <h6 className="name">{item?.customer?.full_name}</h6>
              <div className="spacer">-</div>
              <h6 className="date">{moment(item?.customer?.created_at).format("MMMM Do YYYY, h:mm a")}</h6>
            </div>
            <div className="descrip">
              <p>{item?.comment}</p>
              <div className="rating mb-3">
                <div className="stars">
                  <Rating size={18} readonly={true} ratingValue={Math.round(item?.rating) === 1 ? "20" : Math.round(item?.rating) === 2 ? "40" : Math.round(item?.rating) === 3 ? "60" : Math.round(item?.rating) === 4 ? "80" : Math.round(item?.rating) === 5 ? "100" : null} />

                  <div className="star-count">
                    <span>{item?.rating}</span>
                  </div>
                </div>
                <div className="rply-btn">
                  { seller && <a onClick={() => setFormActive(true)}>Reply</a>}
                </div>
              </div>
            </div>
            {item.replies.map((reply, repIndex) => {
              return (
                <ReplyBox
                  reply={reply}
                  key={repIndex}
                  seller={seller}
                  refreshReviews={() => {
                    refreshReviews();
                  }}
                />
              );
            })}
            {formActive && (
              <ReplyFormComponent
                reply={initialReplyForm}
                seller={seller}
                resetActiveForm={setFormActive}
                refreshReviews={() => {
                  refreshReviews();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const ReplyBox = ({ reply, refreshReviews, seller }) => {
  const nestedReply = (reply.replies || []).map((comment, index) => {
    return <ReplyBox key={index} reply={comment} refreshReviews={refreshReviews} seller={seller} type="child" />;
  });
  const [formActive, setFormActive] = useState(false);

  return (
    <div className="contentDv" class="ml-4">
      <div className="info">
        <h6 className="name">
          {reply?.replyable?.full_name ?? reply?.replyable?.f_name + " " + reply?.replyable?.l_name} {(reply?.replyable?.role == "Customer") || reply?.replyable?.role == "Trader" ? !seller ? "": "("+reply?.replyable?.role+")" : seller ? "":"(Seller)"}
        </h6>
        <div className="spacer">-</div>
        <h6 className="date">{moment(reply?.created_at).format("MMMM Do YYYY, h:mm a")}</h6>
      </div>
      <div className="descrip">
        <p>{reply?.comment}</p>
        <div className="rply-btn">
          {/* <a onClick={() => setFormActive(true)}>Reply</a> */}
        </div>
      </div>
      {nestedReply}
      {formActive && (
        <ReplyFormComponent
          reply={reply}
          seller={seller}
          resetActiveForm={setFormActive}
          refreshReviews={() => {
            refreshReviews();
          }}
        />
      )}
    </div>
  );
};

const ReplyFormComponent = ({ reply, refreshReviews, seller, resetActiveForm }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.AuthReducer.token);
  const sellerToken = useSelector((state) => state.SellerReducer.sellerToken);

  const initialReplyForm = {
    review_id: reply.review_id,
    comment: "",
    parent_id: reply?.id ?? 0,
  };

  const submitReply = (values) => {
    const userToken = token != null && token != "" ? token : sellerToken;
    PostReply(values, userToken, seller)
      .then((res) => {
        // debugger;
        setLoading(false);
        resetActiveForm(false);
        refreshReviews();
        toast.success("reply successfully send");
      })
      .catch((err) => {
        // debugger;
        setLoading(false);
        console.log(err?.response?.data?.message);
        if (err?.response?.data?.message === "Unauthenticated.") {
          toast.error("Please Login");
        }
      });
  };

  return (
    <Formik
      initialValues={initialReplyForm}
      validationSchema={Yup.object({
        review_id: Yup.number().required(),
        comment: Yup.string().required(),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        submitReply(values);
        resetForm(initialReplyForm);
      }}>
      <Form>
        <div class="flex w-100">
          <TextAreaWrapper fieldName={"comment"} />
        </div>
        <button className="btn btn-secondary" type="submit" disabled={loading}>
          {loading ? "Loading.." : "Submit"}
        </button>
      </Form>
    </Formik>
  );
};

export default SingleReviewCard;
