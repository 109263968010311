import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { GetSingleChat, SendMessage, UpdateSeenMessages } from "../../../network/ChatNetwork";
import Pusher from "pusher-js";
import imageProoo from "../../../assets/img/profile-img.png";
import { SellerImageUrl } from "../../../network/ApiUrl";

export default function Messages({ currentChat, currentUser, userType = null, updateCurrentChat = () => {}, token, fetchChatAgain = () => {} }) {
  const [message, setMessage] = useState("");
  const apiType = userType == "App\\User" ? null : "seller";
  const messagesEndRef = useRef(null);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  const formatAmPm = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


  useEffect(() => {
    if (currentChat != null) scrollToBottom();
  }, [currentChat]);

  useEffect(() => {
    scrollToBottom();
    const messageIds = currentChat.messages.map((m) => {
      return m.id;
    });
    const messageSeen = {
      message_ids: messageIds,
      user_id: currentUser.id,
      type: userType,
    };
    if (token !== "" && token !== null && token !== undefined) {
      UpdateSeenMessages(token, messageSeen, apiType)
        .then((res) => {})
        .catch((err) => {
          if (err?.response?.data?.errors.length === 0) {
          } else {
            toast.error(err?.response?.data?.errors?.message);
            toast.error(err?.response?.data?.errors["message"]?.message);
          }
        });
    }
  }, [currentChat.messages]);

  const sendMessage = () => {
    const prepareMessageData = {
      chat_id: currentChat?.id ?? null,
      user_id: currentChat?.user_detail?.id ?? null,
      message: message,
    };
    SendMessage(token, prepareMessageData, apiType)
      .then((res) => {
        const chat_id = res.data.response.data.chat_id ?? null;
        setMessage("");
        updateCurrentChat(chat_id);
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length === 0) {
        } else {
          toast.error(err?.response?.data?.errors?.message);
          toast.error(err?.response?.data?.errors["message"]?.message);
        }
      });
  };

  return (
    <div className="col-12 col-lg-7 col-xl-9">
      <div className="py-2 px-4 border-bottom d-none d-lg-block">
        <div className="d-flex align-items-center py-1">
          <div className="position-relative">
            {currentChat?.user_detail?.image == null ? (
              <>
                <img src={imageProoo} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
              </>
            ) : (
              <>
                <img src={apiType !== null ? `${currentChat?.user_detail?.profile_url}` : `${SellerImageUrl}/${currentChat?.user_detail?.image}`} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
              </>
            )}
          </div>
          <div className="flex-grow-1 pl-3">
            <strong>{currentChat?.user_detail?.f_name}</strong>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="chat-messages p-4">
          {currentChat?.messages?.length === 0 && (
            <div class="d-flex justify-content-center">
              <p>Start chating with the seller</p>
            </div>
          )}
          {currentChat?.messages.map((message, index) => {
            const currentUserMessage = message.userable_type == userType && message.userable_id == currentUser.id;
            const messageClass = message.userable_type == userType && message.userable_id == currentUser.id ? "chat-message-right pb-4" : "chat-message-left pb-4";
            const seenMessages = apiType == null ? message.seen_seller : message.seen_user;
            if (currentUserMessage) {
              return (
                <div className={messageClass}>
                  <div>
                    {currentUser?.image == null ? (
                      <>
                        <img src={imageProoo} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
                      </>
                    ) : (
                      <>
                        <img src={apiType !== null ? `${currentUser?.profile_url}` : `${currentUser?.user_detail?.image}`} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
                      </>
                    )}
                    <div className="text-muted small text-nowrap mt-2">{formatAmPm(new Date(message.created_at))}</div>
                  </div>
                  <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                    <div className="font-weight-bold mb-1">You</div>
                    {message.message}
                    {seenMessages.length !== 0 && <div class="d-flex justify-content-end font-10 mt-4">Seen by {apiType == null ? "Seller" : "Customer"}</div>}
                  </div>
                </div>
              );
            }
            return (
              <div className="chat-message-left pb-4">
                <div>
                  {currentChat?.user_detail?.image == null ? (
                    <>
                      <img src={imageProoo} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
                    </>
                  ) : (
                    <>
                      <img src={apiType !== null ? `${currentChat?.user_detail?.profile_url}` : `${SellerImageUrl}/${currentChat?.user_detail?.image}`} className="rounded-circle mr-1" alt="Sharon Lessman" width={40} height={40} />
                    </>
                  )}
                  <div className="text-muted small text-nowrap mt-2">{formatAmPm(new Date(message.created_at))}</div>
                </div>
                <div className="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                  <div className="font-weight-bold mb-1">{currentChat?.user_detail?.f_name}</div>
                  {message.message}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="flex-grow-0 py-3 px-4 border-top">
        <div className="input-group">
          <input type="text" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type your message" />
          <button onClick={sendMessage} className="btn btn-primary ml-2">
            Send
          </button>
        </div>
      </div>
    </div>
  );
}
