import { SELLER_LOGIN_REQUEST, SELLER_LOGIN_SUCCESS, SELLER_LOGIN_ERROR, SELLER_SIGNUP_REQUEST, SELLER_SIGNUP_SUCCESS, SELLER_SIGNUP_ERROR, SELLER_FORGOT_PASSWORD_REQUEST, SELLER_FORGOT_PASSWORD_SUCCESS, SELLER_FORGOT_PASSWORD_ERROR, SELLER_SET_PASSWORD_REQUEST, SELLER_SET_PASSWORD_SUCCESS, SELLER_SET_PASSWORD_ERROR, SELLER_LOGOUT, SELLER_USER_TOKEN, SELLER_EDIT_PROFILE, SELLER_SET_PRODUCT_DETAIL, SELLER_ALL_ORDERS } from "../SellerTypes";

import { deleteBulkProduct, deleteByProductId, forgotPasswordApi, postAddProduct, postAddProductBulk, PostChangePassword, postSignIn, postSignUp, postUpdateProduct, ResetPasswordApi, UpdateProfile } from "../../network/SellerNetwork";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constant/ConstantFunction";

export const sellerLogin = (data) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    dispatch({ type: SELLER_LOGIN_REQUEST });
    postSignIn(data)
      .then((res) => {
        dispatch({
          type: SELLER_LOGIN_SUCCESS,
          payload: res?.data?.response?.data?.seller,
        });
        dispatch({
          type: SELLER_USER_TOKEN,
          payload: res?.data?.response?.data?.token,
        });

        // dispatch(setError(responsesLogin(res)));

        return resolve(true);
      })
      .catch((err) => {
        dispatch({ type: SELLER_LOGIN_ERROR });
        if (err?.response?.data?.errors.length === 0) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        // toast.error("Please Enter Correct Email or Password");
        return reject(false);
      });
  });

export const sellerSignUp =
  (data, cb = () => {}) =>
  (dispatch) => {
    new Promise(async (resolve, reject) => {
      dispatch({ type: SELLER_SIGNUP_REQUEST });
      postSignUp(data)
        .then((res) => {
          dispatch(setError(responses(res)));
          dispatch({ type: SELLER_SIGNUP_SUCCESS, payload: res?.data?.data });
          // zaam added
          dispatch({
            type: SELLER_LOGIN_SUCCESS,
            payload: res?.data?.response?.data?.user,
          });
          dispatch({
            type: SELLER_USER_TOKEN,
            payload: res?.data?.response?.data?.token,
          });
          // zaam added
          toast.success("Successfully Signup");
          dispatch(setError("success", "Successfully Signup!!!"));
          return resolve(true);
        })
        .catch((err) => {
          console.log("Error", err.response);
          // dispatch(setError(responses(err)));
          console.log(err?.response?.data?.errors[0]?.message);
          dispatch({ type: SELLER_SIGNUP_ERROR });
          toast.error(err?.response?.data?.errors[0]?.message);
          return reject(false);
        })
        .finally(() => {});
    });
  };

// export const CategoriesData = (payload) => {
//   return {
//     type: SELLER_ALL_CATEGORIES,
//     payload,
//   };
// };

export const OrdersData = (payload) => {
  return {
    type: SELLER_ALL_ORDERS,
    payload,
  };
};

export const logout = (payload) => {
  return {
    type: SELLER_LOGOUT,
    payload,
  };
};

// This function use to update seller profile detail
export const EditProfileData = (data, token) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: SELLER_SIGNUP_REQUEST });
    UpdateProfile(data, token)
      .then((res) => {
        //This function update the details related to seller
        dispatch({
          type: SELLER_EDIT_PROFILE,
          payload: res?.data?.data?.profile,
        });
        toast.success("Successfully Updated");
        return resolve(true);
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length === 0) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        return reject(false);
      })
      .finally(() => {});
  });
  return {
    type: SELLER_EDIT_PROFILE,
    data,
  };
};

export const forgotPassword = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: SELLER_FORGOT_PASSWORD_REQUEST });
    forgotPasswordApi(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: SELLER_FORGOT_PASSWORD_SUCCESS });
        
        toast.success(res?.data?.message);
        return resolve(true);
      })
      .catch((err) => {
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);

        dispatch({ type: SELLER_FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const SetChangePassword = (data, token) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: SELLER_SET_PASSWORD_REQUEST });
    PostChangePassword(data, token)
      .then((res) => {
        toast.success("Your password has been reset");
        dispatch({ type: SELLER_SET_PASSWORD_SUCCESS });
        return resolve(true);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors?.password[0]);
        if (err?.response?.data?.errors.length === 0) {
          toast.error(err?.response?.data?.message);
        }
        dispatch({ type: SELLER_SET_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const addProduct =
  (data, cb = () => {}, token) =>
  (dispatch) => {
    new Promise(async (resolve, reject) => {
      postAddProduct(data, token)
        .then((res) => {
          // dispatch(setError(responses(res)));
          
          // dispatch({ type: SELLER_SET_PRODUCT_DETAIL, payload: res?.data?.data });
          toast.success("Product Created Successfully");
          return resolve(true);
        })
        .catch((err) => {
          // console.log("Error", err.response);
          // // dispatch(setError(responses(err)));
          console.log(err?.response?.data?.errors[0]?.message);
          // // dispatch({ type: SELLER_SIGNUP_ERROR });
          toast.error(err?.response?.data?.errors[0]?.message);
          return reject(false);
        })
        .finally(() => {});
    });
  };

export const addProductBulk = (data, token) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    postAddProductBulk(data, token)
      .then((res) => {
        // dispatch(setError(responses(res)));
        
        // dispatch({ type: SELLER_SET_PRODUCT_DETAIL, payload: res?.data?.data });
        toast.success("Products Created Successfully");
        return resolve(true);
      })
      .catch((err) => {
        // console.log("Error", err.response);
        // // dispatch(setError(responses(err)));
        if (err?.response?.data?.errors != undefined && err?.response?.data?.errors.length > 0) {
          console.log(err?.response?.data?.errors[0]?.message);
        } 
        // // dispatch({ type: SELLER_SIGNUP_ERROR });
        toast.error(err?.response?.data?.message);
        return reject(false);
      })
      .finally(() => {});
  });

export const updateProduct =
  (data, cb = () => {}, token) =>
  (dispatch) => {
    new Promise(async (resolve, reject) => {
      postUpdateProduct(data, token)
        .then((res) => {
          // dispatch(setError(responses(res)));
          
          // dispatch({ type: SELLER_SET_PRODUCT_DETAIL, payload: res?.data?.data });
          toast.success("Product Update Successfully");
          return resolve(true);
        })
        .catch((err) => {
          // console.log("Error", err.response);
          // // dispatch(setError(responses(err)));
          console.log(err?.response?.data?.errors[0]?.message);
          // // dispatch({ type: SELLER_SIGNUP_ERROR });
          toast.error(err?.response?.data?.errors[0]?.message);
          return reject(false);
        })
        .finally(() => {});
    });
  };

export const deleteProducts = (data, token) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    deleteBulkProduct(data, token)
      .then((res) => {
        toast.success("Products deleted Successfully");
        return resolve(true);
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length === 0) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        return reject(false);
      })
      .finally(() => {});
  });

export const deleteProduct = (data, token) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    deleteByProductId(data, token)
      .then((res) => {
        toast.success("Product deleted Successfully");
        return resolve(true);
      })
      .catch((err) => {
        if (err?.response?.data?.errors.length === 0) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(err?.response?.data?.errors[0]?.message);
        }
        return reject(false);
      })
      .finally(() => {});
  });
//   end
