import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import "../../assets/css/sellersignup.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sellerSignUp } from "../../redux/actions/SellerAction";
import { signUp } from "../../redux/actions/AuthActions";

const Signup = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const isSignupSeller = useSelector((state) => state.SellerReducer.sellerIsSignup);
  const isSignup = useSelector((state) => state.AuthReducer.isSignup);
  const [profileTabs, setProfileTabs] = useState(1);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (isSignupSeller) {
      setBtnLoading(false);
      Navigate("/seller/profile");
    }
  }, [isSignupSeller]);

  useEffect(() => {
    if (isSignup) {
      Navigate("/");
    }
  }, [isSignup]);

  const changeTabsFunction = (e) => {
    setProfileTabs(e);
  };
  return (
    <div className="sellersignup_page">
      <Header />

      <section className="signup">
        <div className="container">
          <div className="signupBox">
            <h2>Create Account</h2>
            <button type="" className={"btn " + (profileTabs === 1 ? " active" : null)} onClick={() => changeTabsFunction(1)}>
              Signup as Customer
            </button>
            <button type="" className={"btn " + (profileTabs === 2 ? " active" : null)} onClick={() => changeTabsFunction(2)}>
              Signup as Trader
            </button>
            <button type="" className={"btn " + (profileTabs === 3 ? " active" : null)} onClick={() => changeTabsFunction(3)}>
              Signup as Seller
            </button>
            <div className="content-container">
              {profileTabs === 1 && <DynamicForm id={1} />}
              {profileTabs === 2 && <DynamicForm id={2} />}
              {profileTabs === 3 && (
                <Formik
                  initialValues={{ f_name: "", l_name: "", business_name: "", phone: "", email: "", password: "", terms: false }}
                  validationSchema={Yup.object({
                    f_name: Yup.string().max(15, "Must be 15 characters or less").required("this field is required"),
                    l_name: Yup.string().max(15, "Must be 15 characters or less").required("this field is required"),
                    phone: Yup.string().max(20, "Must be 20 characters or less").required("this field is required"),
                    business_name: Yup.string().max(100, "Must be 100 characters or less").required("this field is required"),
                    email: Yup.string().email("Invalid email address").required("this field is required"),
                    password: Yup.string().required("this field is required"),
                    password_confirmation: Yup.string()
                      .required("this field is required")
                      .oneOf([Yup.ref("password")], "Your passwords do not match."),
                    terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setBtnLoading(true);
                    await dispatch(sellerSignUp(values, () => {}));
                    // const posted = requestPostFetch(contactApi, values);
                    resetForm({ values: "" });
                  }}>
                  <Form>
                    <Field name="f_name">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="First Name" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <Field name="l_name">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Last Name" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <Field name="business_name">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="Business Name" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <Field name="email">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="email" class="form-control" placeholder="Email" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <Field name="phone">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="tel" pattern="[0-9]*" class="form-control" placeholder="Phone Number" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <Field name="password">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="password" class="form-control" placeholder="Password" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>

                    <Field name="password_confirmation">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => (
                        <div class="form-group">
                          <input type="password" class="form-control" placeholder="Password Confirmation" {...field} />
                          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                        </div>
                      )}
                    </Field>
                    <div className="remember_dv">
                      <Field name="terms">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => (
                          <div class="form-group">
                            <input type="checkbox" id="cuscheck" class="" placeholder="Password" {...field} />
                            <label for="cuscheck">
                              By Creating account you agree to our <Link to="#">Terms & Conditions</Link>{" "}
                            </label>
                            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                          </div>
                        )}
                      </Field>
                    </div>
                    <button type="submit" disabled={btnLoading}>
                      {btnLoading ? "Loading.." : "Create Account"}
                    </button>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const DynamicForm = ({ id }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const checked = useRef(id === 2 ? "trader" : "customer");
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ f_name: "", l_name: "", phone: "", email: "", password: "", terms: false }}
      validationSchema={Yup.object({
        f_name: Yup.string().max(15, "Must be 15 characters or less").required("this field is required"),
        l_name: Yup.string().max(15, "Must be 15 characters or less").required("this field is required"),
        phone: Yup.string().max(20, "Must be 20 characters or less").required("this field is required"),
        email: Yup.string().email("Invalid email address").required("this field is required"),
        password: Yup.string().required("this field is required"),
        password_confirmation: Yup.string()
          .required("this field is required")
          .oneOf([Yup.ref("password")], "Your passwords do not match."),
        terms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setBtnLoading(true);
        values["is_trader"] = checked.current === "trader" ? true : false;
        await dispatch(signUp(values, () => {}));
        // const posted = requestPostFetch(contactApi, values);
        setBtnLoading(false);
        resetForm({ values: "" });
      }}>
      <Form>
        <Field name="f_name">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="text" class="form-control" placeholder="First Name" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name="l_name">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Last Name" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name="email">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Email" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name="phone">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="tel" pattern="[0-9]*" class="form-control" placeholder="Phone Number" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name="password">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Password" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
        <Field name="password_confirmation">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <input type="password" class="form-control" placeholder="Password Confirmation" {...field} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>

        <div className="remember_dv">
          <Field name="terms">
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => (
              <div class="form-group">
                <input type="checkbox" id="cuscheck" class="" {...field} />
                <label for="cuscheck">
                  By Creating account you agree to our <Link to="#">Terms & Conditions</Link>{" "}
                </label>
                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
              </div>
            )}
          </Field>
        </div>

        <button type="submit" disabled={btnLoading}>
          {btnLoading ? "Loading.." : "Create Account"}
        </button>
      </Form>
    </Formik>
  );
};

export default Signup;
