import {
  SELLER_LOGIN_REQUEST,
  SELLER_LOGIN_SUCCESS,
  SELLER_LOGIN_ERROR,
  SELLER_SIGNUP_REQUEST,
  SELLER_SIGNUP_SUCCESS,
  SELLER_SIGNUP_ERROR,
  SELLER_FORGOT_PASSWORD_REQUEST,
  SELLER_FORGOT_PASSWORD_SUCCESS,
  SELLER_FORGOT_PASSWORD_ERROR,
  SELLER_SET_PASSWORD_REQUEST,
  SELLER_SET_PASSWORD_SUCCESS,
  SELLER_SET_PASSWORD_ERROR,
  SELLER_LOGOUT,
  SELLER_USER_TOKEN,
  SELLER_EDIT_PROFILE,
  SELLER_SET_PRODUCT_DETAIL,
  SELLER_ALL_ORDERS
} from "../SellerTypes";

const initialState = {
  sellerIsLogin: false,
  sellerIsSignup: false,
  sellerLoading: false,
  sellerForgotLoading: false,
  sellerSetPasswordLoading: false,
  sellerUser: {},
  sellerToken: "",
  sellerOrders: [],
  sellerCategoriesData: [],
  sellerBrandData: [],
  sellerProductsData: [],
  sellerCurrentProductDetail: {}
};

const SellerReducer = (state = initialState, action) => {
    switch (action.type) {
      case SELLER_LOGIN_REQUEST: {
        return Object.assign({}, state, {
          loading: true,
        });
      }
      case SELLER_LOGIN_SUCCESS: {
        return Object.assign({}, state, {
          sellerUser: action.payload,
          sellerLoading: false,
          sellerIsLogin: true,
        });
      }

      case SELLER_LOGIN_ERROR: {
        return Object.assign({}, state, {
          sellerLoading: false,
          sellerIsLogin: false,
        });
      }
      case SELLER_USER_TOKEN: {
        return Object.assign({}, state, {
          sellerToken: action.payload,
        });
      }
      case SELLER_SIGNUP_REQUEST: {
        return Object.assign({}, state, {
          sellerLoading: true,
        });
      }
      case SELLER_SIGNUP_SUCCESS: {
        return Object.assign({}, state, {
          sellerUser: action.data,
          sellerIsSignup: true,
          sellerLoading: false,
        });
      }
      case SELLER_SIGNUP_ERROR: {
        return Object.assign({}, state, {
          sellerLoading: false,
          sellerIsLogin: false,
          sellerIsSignup: false,
        });
      }
      case SELLER_FORGOT_PASSWORD_REQUEST: {
        return Object.assign({}, state, {
          sellerForgotLoading: true,
          sellerIsSuccess: false,
        });
      }
      case SELLER_FORGOT_PASSWORD_SUCCESS: {
        return Object.assign({}, state, {
          sellerForgotLoading: false,
          sellerIsSuccess: true,
        });
      }
      case SELLER_FORGOT_PASSWORD_ERROR: {
        return Object.assign({}, state, {
          sellerForgotLoading: false,
          sellerIsSuccess: false,
        });
      }
      case SELLER_SET_PASSWORD_REQUEST: {
        return Object.assign({}, state, {
          sellerSetPasswordLoading: true,
        });
      }
      case SELLER_SET_PASSWORD_SUCCESS: {
        return Object.assign({}, state, {
          sellerSetPasswordLoading: false,
          sellerUser: {},
          sellerIsLogin: false,
          sellerIsSignup: false,
        });
      }
      case SELLER_SET_PASSWORD_ERROR: {
        return Object.assign({}, state, {
          sellerSetPasswordLoading: false,
        });
      }
      case SELLER_EDIT_PROFILE: {
        return Object.assign({}, state, {
          sellerUser: action.payload,
        });
      }
      case SELLER_LOGOUT:
        return Object.assign({}, state, {
          sellerUser: {},
          sellerIsLogin: false,
          sellerIsSignup: false,
          sellerToken: null,
        });
// Below is the list of product set
      case SELLER_SET_PRODUCT_DETAIL:
          return {
            sellerCurrentProductDetail: action.payload,
          };
      case SELLER_ALL_ORDERS:
          return Object.assign({}, state, {
            sellerOrders: action.payload
          });

      default:
        return state;
    }

}

export default SellerReducer;
