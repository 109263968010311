import React from "react";
import "../../assets/css/thankyou.css";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link, useLocation } from "react-router-dom";

const Thankyou = () => {
  const Location = useLocation();
  return (
    <>
      <Header />
      <section className="thankyou">
        <div className="container">
          <div className="happyShopping">
            <h3 className="font-30 fw-600 pb-4">Thank you for Shopping</h3>
            <p className="pb-4">
              Your order details has been sent to your email address
            </p>
            <p>Order Id: {Location?.state?.data?.id}</p>
            <p>Amount: {Location?.state?.data?.order_amount}</p>
            <p>Status: {Location?.state?.data?.order_status}</p>
            <div className="button-group">
              <Link to="/" className="btn">
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Thankyou;
