import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { ApplyCoupons, PlaceOrder } from "../../../network/Network";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClearCart, CouponRemoved } from "../../../redux/actions/CartActions";
import { verifyCouponApplicable } from "../../../Utils/coupon";
import { Form, Formik } from "formik";
import { updateShipmentDetails } from "../../../redux/actions/AuthActions";

const steps = ["Select campaign settings", "Create an ad group", "Create an ad"];

export default function StepForm(props) {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { CheckOutData } = props;
  const Token = useSelector((state) => state.AuthReducer.token);
  const userDataRedux = useSelector((state) => state.AuthReducer.users);
  const countryData = useSelector((state) => state.AuthReducer.country);
  const shipmentDetails = useSelector((state) => state.AuthReducer.shipmentDetails);
  const couponData = useSelector((state) => state.CartReducer.coupon) ?? null;
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [userFormData, setUserFormData] = useState({
    f_name: shipmentDetails?.f_name ?? userDataRedux?.f_name ?? "",
    l_name: shipmentDetails?.l_name ?? userDataRedux?.l_name ?? "",
    phone: shipmentDetails?.phone ?? userDataRedux?.phone ?? "",
    email: shipmentDetails?.email ?? userDataRedux?.email ?? "",
    region: shipmentDetails?.region ?? userDataRedux?.region ?? "0",
    postalCode: shipmentDetails?.postalCode ?? userDataRedux?.postalCode ?? "",
    city: shipmentDetails?.city ?? userDataRedux?.city ?? "",
    street_address: shipmentDetails?.street_address ?? userDataRedux?.street_address ?? "",
    save_data_for_future: shipmentDetails  != null && Object.keys(shipmentDetails).length === 0 ? true : false,
  });
  const [cardToken, setCardToken] = useState(shipmentDetails?.cardToken ?? "");
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    if (couponData != null) {
      let checkCoupon = verifyCouponApplicable(couponData, Number(CheckOutData?.total).toFixed(2));
      if (checkCoupon.status) {
        setCouponDiscount(checkCoupon.discount);
      }
    }
  }, [couponData, CheckOutData?.total]);

  useEffect(() => {
    setCardToken(shipmentDetails?.cardToken ?? '');
  },[shipmentDetails?.cardToken])

   useEffect(() => {
     setUserFormData((prev)=> {
      return {
        ...prev,
        save_data_for_future: shipmentDetails?.save_data_for_future ?? false
      };
     });
   }, [shipmentDetails?.save_data_for_future]);


  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const PlaceOrderHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    
    let saveCheckoutData = {
      ...userFormData,
      cardToken: cardToken,
    };

    if(userFormData.save_data_for_future){
      dispatch(updateShipmentDetails(saveCheckoutData));
    }

    let data = {
      cart: CheckOutData?.CartData,
      customer_info: userFormData,
      coupon_data: couponData,
      discount: couponDiscount == null ? 0 : couponDiscount,
      stripe_token: cardToken,
      role: Token == undefined ? "guest" : userDataRedux.role,
    };
    PlaceOrder(data, Token)
      .then((res) => {
        setLoading(false);
        dispatch(ClearCart());
        dispatch(CouponRemoved({}));
        toast.success("Order placed successfully!");
        Navigate(`/thankyou`, { state: { data: res?.data?.data?.order } });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const StepOneContinue = (userDetails) => {
    setLoading(true);
	setUserFormData(userDetails);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
      setLoading(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setLoading(false);
  };

  const StepTwoContinue = (e) => {
    e.preventDefault();
    if (!cardToken) {
      // toast.error("Invalid Card Details");
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption"></Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{/* {label} */}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography> */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {activeStep + 1 == 1 ? <StepOne handleNext={handleNext} userFormData={userFormData} loading={loading} StepOneContinue={StepOneContinue} CheckOutData={CheckOutData} /> : activeStep + 1 == 2 ? <StepTwo StepTwoContinue={StepTwoContinue} setCardToken={setCardToken} handleNext={handleNext} CheckOutData={CheckOutData} cardToken={cardToken} /> : <StepThree CheckOutData={CheckOutData} userFormData={userFormData} PlaceOrderHandler={PlaceOrderHandler} loading={loading} />}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep > 0 && (
              <Button color="inherit" onClick={handleBack} style={{ cursor: "pointer" }}>
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            {/* <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button> */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
