import React from "react";
import { Redirect, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function SellerProtectedRoutes({ component: Component, ...restOfProps }) {
  const isSignup = useSelector((state) => state.SellerReducer.sellerIsSignup);
  const isLogin = useSelector((state) => state.SellerReducer.sellerIsLogin);

  return <>{(isLogin || isSignup) ? <Outlet /> : <Navigate to="/sellerlogin" />}</>;
}

export default SellerProtectedRoutes;
