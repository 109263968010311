import React, { useEffect, useState } from "react";
import notificationimg from "../../../assets/img/notificationimg.png";
import "../../../assets/css/sellernotification.css";
import { GetAllNotifications, MarkNotification, MarkNotificationBulk } from "../../../network/SellerNetwork";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const token = useSelector((state) => state.SellerReducer.sellerToken);

  // Get All orders
  useEffect(() => {
      GetAllNotifications(token)
        .then((res) => {
          setNotifications(res?.data?.data?.notifications);
          let notiIds = res?.data?.data?.notifications.map((x)=>x.id) ?? [];
          // updateReadOnAll(notiIds);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);


  const handleClickNotification = (data) => {
     MarkNotification(data, token)
      .then((res) => {
        Navigate("/seller/order-detail/" + data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
      });
  }
 

  return (
    <>
      <div className="notification_page">
        <div className="container">
          <div className="notification_card">
            {notifications.map((notification, index)=>{
              return (
                <div key={index} className={notification.read === 0 ? 'notification_content active': 'notification_content'} onClick={()=> handleClickNotification(notification.id)}>
                  <div className="notication_img">
                    <img className="img-fluid" src={notificationimg}></img>
                    <p>{notification.title}</p>
                  </div>
                  <div className="notification_para">
                    <p>{notification.description}</p>
                    <span>1 Nov 10:30 am | 2022</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
