import React, { useEffect, useRef, useState } from "react";
import SellerSideBar from "../SellerSideBar";
import SellerFooter from "../../../components/SellerFooter";
import "../../../../assets/css/sellerproddetail.css";
import uploadimg from "../../../../assets/img/uploadimg.png";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { CkEditorWrapper, ImageWrapper, SelectWrapper, SwitchWrapper, TextAreaWrapper, TextWrapper, toFormData } from "../../../../Form/Element";
import Variation from "./Components/Variation";
import ProductPriceSection from "./Components/ProductPrice";
import { addProduct } from "../../../../redux/actions/SellerAction";
import { GetAllAttribute } from "../../../../network/SellerNetwork";
import SellerHeader from "../../../components/SellerHeader";
import { useParams, useSearchParams } from "react-router-dom";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const fileSize = 1000000;

const checkFileSize = (element) => {
  return element.file.size > fileSize;
};
const checkFileType = (element) => {
  return !SUPPORTED_FORMATS.includes(element.file.type);
};

// export const validateImageType = (value) => {
//   if (value) {
//     let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
//     return SUPPORTED_FORMATS.includes(type);
//   }
// };

const AddNewProduct = () => {
  const currentCategories = useSelector((state) => state.AuthReducer.categoriesData);
  const currentColors = useSelector((state) => state.AuthReducer.colorsData);
  const currentBrands = useSelector((state) => state.AuthReducer.brands);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [searchParams] = useSearchParams();
  const [currentAttributes, setCurrentAttributes] = useState([]); 
  // Get All attributes
  useEffect(() => {
    if (currentAttributes !== undefined && currentAttributes.length === 0) {
      GetAllAttribute(token)
        .then((res) => {
          setCurrentAttributes(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const formProps = {
    trade: searchParams.get("is_trade") == 1 ? true:false ,
    currentColors: currentColors,
    currentBrands: currentBrands,
    currentCategories: currentCategories,
    currentAttributes: currentAttributes,
  };

  return (
    <>
      <div className="sellerproddetail_page addnewproduct_sec">
        <SellerHeader />
        <div className="container-fluid">
          <div className="sellerproddetail_sec">
            <div className="seller_heading">
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <MainForm {...formProps} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

const MainForm = ({ trade = false, currentCategories, currentBrands, currentColors, currentAttributes }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const unitMeasurement = ["kg", "pc", "gms", "ltrs"];
  const [choices, setChoices] = useState([]);
  const [isTrade, setIsTrade] = useState(false);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const token = useSelector((state) => state.SellerReducer.sellerToken);

  useEffect(()=>{
    setIsTrade(trade);
  }, [trade])

  const productRef = useRef(null);
  const dispatch = useDispatch();

  const attributeOptions = currentAttributes.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const categoryOptions = currentCategories.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const unitOption = unitMeasurement.map((x) => {
    return {
      label: x,
      value: x,
    };
  });

  const brandsOption = currentBrands.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const handleSubCategories = (currentCatId) => {
    let tempCat = currentCategories.find((x) => x.id === currentCatId);

    setSubcategoryOptions((prev) => {
      return tempCat.childes.map((ele) => {
        return { label: ele.name, value: ele.id };
      });
    });
  };

  const handleImages = () => {};

  const handleTrade = (currentVal) => {
    setIsTrade(currentVal);
  };

  const handleChoices = (newChoice) => {
    setChoices(newChoice);
  };

  const categoryIds = currentCategories.map((x) => {
    return x.id;
  });

  const formInitialValue = {
    name: "",
    description: "",
    unit: "",
    category_id: "",
    sub_category_id: "",
    brand_id: "0",
    price: "0",
    is_trade: trade,
    trade_qty: 1,
    colors_active: false,
    colors: [],
    attributes: [],
    images: [],
    image: "",
    unit_price: 1,
    purchase_price: 1,
    tax: 0,
    tax_type: "0",
    discount: 0,
    discount_type: "",
    current_stock: 0,
    meta_title: "",
    meta_description: "",
    meta_image: "",
    video_link: "",
    variations: [],
    choices: [],
  };

  return (
    <Formik
      ref={productRef}
      initialValues={formInitialValue}
      validationSchema={Yup.object({
        unit: Yup.string().oneOf(unitMeasurement, "You must select any one unit"),
        category_id: Yup.number().oneOf(categoryIds, "You must select any one category"),
        images: Yup.array()
          .min(1, "at least one image is required")
          .required("images is required")
          .test("fileSize", "File is too large", (value) => {
            return value.length > 0
              ? value.some((ele) => {
                  var testFile = !checkFileSize(ele);
                  return testFile;
                })
              : true;
          })
          .test("fileType", "File type is not support", (value) => {
            return value.length > 0
              ? value.some((val) => {
                  var testFile = !checkFileType(val);
                  return testFile;
                })
              : true;
          }),
        image: Yup.array()
          .min(1, "Thumbnail image is required")
          .required("Thumbnail image is required")
          .test("fileSize", "File is too large", (value) => {
            return value.length > 0
              ? value.some((ele) => {
                  var testFile = !checkFileSize(ele);
                  return testFile;
                })
              : true;
          })
          .test("fileType", "Please upload the right file type", (value) => {
            return value.length > 0
              ? value.some((val) => {
                  var testFile = !checkFileType(val);
                  return testFile;
                })
              : true;
          }),
        tax: Yup.number().min(0, "").required(),
        unit_price: Yup.number().min(1).required().label("Unit Price"),
        purchase_price: Yup.number().min(1).required().label("Purchase Price"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setBtnLoading(true);
        // TODO: convert json with formData or try to submit data with json and files
        // TODO: use Content-Type multipart/form-data
        // TODO: care the submission of
        var { image, meta_image, images, attributes, variations, choices, colors, ...rest } = values;
        var collectImageFiles = images.map((e) => e.file);
        var prepareFormObject = {
          ...rest,
          colors: JSON.stringify(colors),
          variations: JSON.stringify(variations),
          choices: JSON.stringify(choices),
          attributes: JSON.stringify(attributes),
        };
        var formValues = toFormData(prepareFormObject);
        collectImageFiles.forEach((image, index) => {
          formValues.append("images[" + index + "]", image);
        });
        formValues.append("image", image[0]?.file ?? null);
        formValues.append("meta_image", meta_image[0]?.file);

        let x = await dispatch(addProduct(formValues, () => {}, token));
        setBtnLoading(false);
        // const posted = requestPostFetch(contactApi, values);
        // resetForm({ values: "" });
      }}>
      <Form>
        <div className="proddetail_sec">
          <div className="proddetail_head">
            <h3>Add New Product</h3>
          </div>
          <div className="prodimg_sec">
            <p>Product Thumbnail Image</p>
            <ImageWrapper
              fieldName={"image"}
              callBack={(e) => {
                handleImages(e);
              }}
            />
          </div>
          <div className="prodimg_sec">
            <p>Product Image</p>
            <ImageWrapper
              fieldName={"images"}
              callBack={(e) => {
                handleImages(e);
              }}
              multi={true}
            />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Product Title</h6>
            <Field name="name">
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <div class="form-group">
                  <input type="text" class="form-control" id="prodtitle" placeholder="Enter Product Title" {...field} />
                  {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
          </div>
          <div className="proddetialname flex-column align-items-start justify-content-around">
            <h6 className="prodtitle">Product Description</h6>
            <CkEditorWrapper fieldName={"description"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Category</h6>
            <SelectWrapper options={categoryOptions} callBack={handleSubCategories} fieldName={"category_id"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Sub Category</h6>
            <SelectWrapper options={subcategoryOptions} fieldName={"sub_category_id"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Brand</h6>
            <SelectWrapper options={brandsOption} fieldName={"brand_id"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Unit</h6>
            <SelectWrapper options={unitOption} fieldName={"unit"} />
          </div>
          <div className="proddetialname">
            <SwitchWrapper fieldName={"is_trade"} defaultValue={trade} callBack={handleTrade} label="Is Trade" />
            {isTrade && (
              <div class="w-75 d-flex flex-row justify-content-end align-items-center">
                <h6 class="w-50">Trade Qty</h6>
                <Field name="trade_qty">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div class="form-group">
                      <input type="text" class="form-control" id="prodprice" placeholder="Enter Qty" {...field} />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
        <Variation choices={choices} colors={currentColors} callback={handleChoices} attributeOptions={attributeOptions} />
        <ProductPriceSection choices={choices} />
        <SeoSection />
        <div className="button_dv">
          <button type="submit" className="btn" disabled={btnLoading}>
            {btnLoading ? "Loading.." : "Add Product"}
            {/* Add Product */}
          </button>
        </div>
      </Form>
    </Formik>
  );
};

// This section is specifically for seo tags and input
const SeoSection = () => {
  const handleImages = () => {};
  return (
    <div class="proddetail_sec">
      <div className="setchanges_dv">
        <h5>Seo Section</h5>
        <div className="proddetialname">
          <h6 className="prodtitle">Meta Title</h6>
          <div class="form-group">
            <TextWrapper fieldName={"meta_title"} label={"meta title"} />
          </div>
        </div>
        <div className="proddetialname">
          <h6 className="prodtitle">Meta Description</h6>
          <TextAreaWrapper fieldName={"meta_description"} label={"meta description"} />
        </div>
        <div className="prodimg_sec">
          <p>Meta Image</p>
          <ImageWrapper
            fieldName={"meta_image"}
            callBack={(e) => {
              handleImages(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewProduct;
