import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SellerHeader from "../../components/SellerHeader";
import "../../../assets/css/productinventory.css";
import sellerphone from "../../../assets/img/sellerphone.png";
import SellerFooter from "../../components/SellerFooter";
import SellerSideBar from "./SellerSideBar";
import { useSelector } from "react-redux";
import { GetOrder } from "../../../network/SellerNetwork";
import { ImageUrl } from "../../../network/ApiUrl";
const MyOrderDetail = () => {
  let { order_id } = useParams();
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [order, setOrder] = useState({});
  useEffect(() => {
    if (order_id !== undefined) {
      GetOrder(order_id, token)
        .then((res) => {
          setOrder(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <div className="seller_prodinv_page orderdetail_page">
        <SellerHeader />
        <div className="container-fluid">
          <div className="seller_prodinv_sec">
            <div>
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              {Object.keys(order).length > 0 && order !== undefined && (
                <div className="col-md-9">
                  <div className="totalcard">
                    <div className="form-head">
                      <div className="seller_head">
                        <div className="d-flex justify-content-between">
                          <h3>Orders ID #{order.id}</h3>
                          <StatusSpan status={order.status} />
                        </div>
                      </div>
                    </div>
                    <div className="order-table-wrapper table-responsive">
                      <table className="table">
                        <tbody>
                          {order.details.length > 0 &&
                            order.details.map((detail, index) => {
                              const productDetailProps = {
                                orderQty: detail.qty,
                                product: detail.product,
                                deliveryStatus: detail.delivery_status,
                              };

                              return <ProductDetails key={index} {...productDetailProps} />;
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

const ProductDetails = ({ product, order_date = "", orderQty = 0, deliveryStatus = null }) => {
  return (
    <tr className="row2">
      <td className="pname">
        <div className="prodinv_phone">
          <img src={`${ImageUrl}/thumbnail/${product.thumbnail}`} className="img-fluid" alt="" />
        </div>
        <div>
          <p>Product Title:</p>
          <p>Order Type:</p>
          <p>Quantity:</p>
          <p>Order Status:</p>
        </div>
      </td>
      <td className="orderproddetail">
        <p>
          {product.name.substring(0, 60) + "..."}{" "}
          <Link to={"/seller/productdetail/" + product.id}>
            <i class="fa fa-eye text-dark"></i>
          </Link>
        </p>
        <p>{product.is_trade === 1 ? "Trade Order" : "Regular Order"}</p>
        <p className="qtnty_value">{orderQty}</p>
      </td>
    </tr>
  );
};


const StatusSpan = ({ status = null }) => {
  switch (status) {
    case "completed":
      return <button className="active">{status}</button>;
    case "delivered":
      return <button className="active">{status}</button>;
    case "pending":
      return <button className="inactive">{status}</button>;
    case "cancelled":
      return <button className="inactive">{status}</button>;
    case "shipping":
      return <button className="shipping">{status}</button>;
    default:
      return <button className="processing">{status}</button>;
  }
};


export default MyOrderDetail;
