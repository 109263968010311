import React from "react";
import SellerHeader from "../../components/SellerHeader";
import sellerphone from "../../../assets/img/sellerphone.png";
import SellerSideBar from "./SellerSideBar";
import SellerFooter from "../../components/SellerFooter";
import "../../../assets/css/sellerproddetail.css";
import reviewimg from "../../../assets/img/reviewimg.png";

const EditProfileDetail = () => {
  return (
    <>
      <div className="sellerproddetail_page sellereditdetail">
        <SellerHeader />
        <div className="container-fluid">
          <div className="sellerproddetail_sec">
            <div className="seller_heading">
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <div className="proddetail_sec">
                  <div className="proddetail_head">
                    <h3>Edit-Product Detail</h3>
                  </div>
                  <div className="prodimg_sec">
                    <p>Product Image</p>
                    <img src={sellerphone} alt="" className="img-fluid" />
                  </div>
                  <div className="order-table-wrapper table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Product Title</h6>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                id="fname"
                                name="fname"
                                placeholder="Samsung X"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Category</h6>
                          </td>
                          <td>
                            <div class="form-group">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Electronic</option>
                                <option>Electronic</option>
                                <option>Electronic</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Sub Category</h6>
                          </td>
                          <td>
                            <div class="form-group">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Mobile</option>
                                <option>Mobile</option>
                                <option>Mobile</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Price</h6>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                id="fname"
                                name="fname"
                                placeholder="$700.0"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Stock Quantity</h6>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                id="fname"
                                name="fname"
                                placeholder="Update Stock"
                              />
                            </div>
                          </td>
                          <td>
                            <p className="proddesc reddesc">
                              12 Remaining from 100
                            </p>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Discount</h6>
                          </td>
                          <td>
                            <div class="form-group">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>No Deal Applied</option>
                                <option>No Deal Applied</option>
                                <option>No Deal Applied</option>
                              </select>
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Color Family</h6>
                          </td>
                          <td>
                            <div class="form-group">
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1"
                              >
                                <option>Multicolor</option>
                                <option>Multicolor</option>
                                <option>Multicolor</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="color_family_dv">
                              <img
                                src={sellerphone}
                                className="img-fluid"
                                alt=""
                              />
                              <img
                                src={sellerphone}
                                className="img-fluid"
                                alt=""
                              />
                              <img
                                src={sellerphone}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </td>
                          <td>
                            <div className="colorfamilydesc">
                              <i className="fa fa-plus"></i>
                              <span>You can upload 8 images max</span>
                            </div>
                          </td>
                        </tr>
                        <tr className="row2">
                          <td>
                            <h6 className="prodtitle">Product SKU</h6>
                          </td>
                          <td>
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                id="fname"
                                name="fname"
                                placeholder="SX1234"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="proddetialname">
                    <h6 className="prodtitle">Product Title</h6>
                    <p className="proddesc">Samsung X</p>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Category</h6>
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Electronic</option>
                        <option>Electronic</option>
                        <option>Electronic</option>
                      </select>
                    </div>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Sub Category</h6>
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Mobile</option>
                        <option>Mobile</option>
                        <option>Mobile</option>
                      </select>
                    </div>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Price</h6>
                    <p className="proddesc">$700.0</p>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Stock Quantity</h6>
                    <p className="proddesc">Update Stock</p>
                    <p className="proddesc reddesc">12 Remaining from 100</p>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Product Status</h6>
                    <p className="proddesc">Samsung X</p>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Discount</h6>
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>No Deal Applied</option>
                        <option>No Deal Applied</option>
                        <option>No Deal Applied</option>
                      </select>
                    </div>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Color Family</h6>
                    <div class="form-group">
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option>Multicolor</option>
                        <option>Multicolor</option>
                        <option>Multicolor</option>
                      </select>
                    </div>
                    <div className="color_family_dv">
                      <img src={sellerphone} className="img-fluid" alt="" />
                      <img src={sellerphone} className="img-fluid" alt="" />
                      <img src={sellerphone} className="img-fluid" alt="" />
                    </div>
                    <div className="colorfamilydesc">
                      <i className="fa fa-plus"></i>
                      <span>You can upload 8 images max</span>
                    </div>
                  </div>
                  <div className="proddetialname">
                    <h6 className="prodtitle">Product SKU</h6>
                    <p className="proddesc">SX1234</p>
                  </div> */}
                </div>
                <div class="proddetail_sec">
                  <div className="setchanges_dv">
                    <h5>Set Delivery Charges</h5>
                    <div className="proddetialname">
                      <h6 className="prodtitle">Delivery Option</h6>
                      <p className="proddesc">Delivery Cost</p>
                    </div>
                    <div className="proddetialname">
                      <h6 className="prodtitle">Nationwide</h6>
                      <p className="proddesc">$20</p>
                    </div>
                    <div className="proddetialname">
                      <h6 className="prodtitle">International</h6>
                      <p className="proddesc">$35</p>
                    </div>
                  </div>
                </div>
                <div className="button_dv">
                  <button type="" className="btn">
                    Save Changes
                  </button>
                  <button type="" className="btn grey_btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

export default EditProfileDetail;
