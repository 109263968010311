import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { TextWrapper, NumberWrapper, SelectWrapper } from "../../../../../Form/Element";

/**
 * This function is used to arrange product variations with their attributes
 *
 *
 * @param {Array} base
 * @param {Array} variations
 * @returns
 */
function add_variations_to_array(base, variations) {
  let ret = [];
  for (let e of base) {
    for (let variation of variations) {
      ret.push({ type: e.type + "-" + variation.type, price: 0, qty: 0, sku: "sku-" + e.type + "-" + variation.type });
    }
  }
  return ret;
}

// This section is specifically for product price, tax and quantity on the basis of variation
const ProductPriceSection = ({ choices, formValues = {}, selectedVariation = [], totalStock = 0 }) => {
  const [variations, setVariations] = useState(selectedVariation);
  const [currentStocks, setCurrentStocks] = useState(totalStock);
  const { setFieldValue } = useFormikContext();

  const discountOption = ["flat", "percent"].map((x) => {
    return {
      label: x,
      value: x,
    };
  });

  const handleUnitPrice = (e) => {
    let tempVariations = variations.map((x) => {
      return {
        ...x,
        price: e.target.value,
      };
    });
    setVariations(tempVariations);
  };

  const prepareVariations = (main) => {
    let tempVariations = main[0];
    if (main.length > 1) {
      for (let i in main) {
        if (i > 0) {
          tempVariations = add_variations_to_array(tempVariations, main[i]);
        }
      }
      return tempVariations;
    } else {
      return tempVariations.map((t) => {
        return { type: t.type, price: 0, qty: 0, sku: "sku-" + t.type };
      });
    }
  };

  const handleVariationPriceAndQty = (element, index, column) => {
    var copyVariation = variations;
    copyVariation[index][column] = element.target.value;
    if (column === "qty") {
      let sumQty = copyVariation.reduce((n, current) => n + parseInt(current["qty"]), 0);
      setFieldValue("current_stock", sumQty);
      setCurrentStocks(sumQty);
    }
    setVariations(copyVariation);
  };

  // Execute the variation arrangement every time the choices changes
  useEffect(() => {
    let numberOfChoice = choices.length;
    if (numberOfChoice > 0) {
      let prepareChoices = choices.map((ele) => {
        return ele.options.map((opt) => {
          return { type: opt.label };
        });
      });
      let finalVariations = prepareVariations(prepareChoices);
      finalVariations.forEach((x)=>{
        var formVariationIndex = selectedVariation.find((v)=> v.type === x.type)
        x.price = formVariationIndex?.price ?? 0;
        x.qty = formVariationIndex?.qty ?? 0;
      })
      if (finalVariations !== undefined) {
        setVariations(finalVariations);
      }
    }
  }, [choices]);

  return (
    <div class="proddetail_sec">
      <div className="setchanges_dv">
        <h5>Product price & stock</h5>
        <div className="proddetialname">
          <h6 className="prodtitle">Unit Price</h6>
          <NumberWrapper defaultValue={formValues.unit_price ?? 0} fieldName={"unit_price"} callBack={handleUnitPrice} />
        </div>
        <div className="proddetialname">
          <h6 className="prodtitle">Purchase Price</h6>
          <NumberWrapper defaultValue={formValues.purchase_price ?? 0} fieldName={"purchase_price"} />
        </div>
        <div className="proddetialname">
          <h6 className="prodtitle">Tax</h6>
          <NumberWrapper defaultValue={formValues.tax ?? 0} fieldName={"tax"} />
          <SelectWrapper defaultValue={{ label: formValues?.tax_type ?? "", value: formValues?.tax_type ?? "" }} options={discountOption} fieldName={"tax_type"} />
        </div>
        <div className="proddetialname">
          <h6 className="prodtitle">Discount</h6>
          <NumberWrapper defaultValue={formValues.discount ?? 0} fieldName={"discount"} />
          <SelectWrapper defaultValue={{ label: formValues?.discount_type ?? "", value: formValues?.discount_type ?? "" }} options={discountOption} fieldName={"discount_type"} />
        </div>
        {variations.length > 0 && <VariationList variations={variations} formVariation={selectedVariation} handleChange={handleVariationPriceAndQty} />}
        <div className="proddetialname">
          <h6 className="prodtitle">Total quantity</h6>
          <NumberWrapper defaultValue={currentStocks} fieldName={"current_stock"} />
        </div>
      </div>
    </div>
  );
};

const VariationList = ({ variations, handleChange, formVariation }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("variations", variations);
  }, [variations, setFieldValue]);

  return (
    <>
      {variations.length > 0 && (
        <div className="proddetialname">
          <h6 className="prodtitle">Variant</h6>
          <h6 className="prodtitle">Variant Price</h6>
          <h6 className="prodtitle">Variant SKU</h6>
          <h6 className="prodtitle">Variant Qty</h6>
        </div>
      )}

      {variations.map((variation, index) => {
        // var defaultPrice = formVariation.find((x) => x.type === variation.type).price ?? 0;
        // var defaultQty = formVariation.find((x) => x.type === variation.type).qty ?? 0;
        return (
          <div key={index} className="proddetialname">
            <h6 className="prodtitle">{variation.type}</h6>
            <div class="d-flex w-100">
              <NumberWrapper additionalClass="w-75" form={false} defaultValue={variation.price} fieldName={"variant_price"} callBack={(e) => handleChange(e, index, "price")} />
              <TextWrapper additionalClass="w-75" form={false} defaultValue={variation.sku} fieldName="sku" callBack={(e) => handleChange(e, index, "sku")} />
              <NumberWrapper additionalClass="w-75" form={false} defaultValue={variation.qty} fieldName={"variant_qty"} callBack={(e) => handleChange(e, index, "qty")} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductPriceSection;
