import React, { useState } from "react";
import "../../../assets/css/productinventory.css";
import SellerSideBar from "./SellerSideBar";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { EditProfileData } from "../../../redux/actions/SellerAction";
import SellerHeader from "../../components/SellerHeader";
import SellerFooter from "../../components/SellerFooter";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const currentSeller = useSelector((state) => state.SellerReducer.sellerUser);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [btnLoading, setBtnLoading] = useState(false);

  return (
    <>
      <div className="seller_prodinv_page profileinfo_page">
        <SellerHeader />
        <div className="container-fluid">
          <div className="seller_prodinv_sec">
            <div>
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <div className="totalcard">
                  <div className="form-head">
                    <div className="seller_head">
                      <div className="">
                        <h3>Personal Info</h3>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{ f_name: currentSeller.f_name, l_name: currentSeller.l_name, business_name: currentSeller.business_name, phone: currentSeller.phone, email: currentSeller.email }}
                    validationSchema={Yup.object({
                      f_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
                      l_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
                      business_name: Yup.string().max(100, "Must be 100 characters or less").required("Required"),
                      phone: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
                      email: Yup.string().email("Invalid email address").required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setBtnLoading(true);
                      await dispatch(EditProfileData(values, token));
                      setBtnLoading(false);

                      // const posted = requestPostFetch(contactApi, values);
                    }}>
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <Field name="f_name">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="text" class="form-control" id="inputfname" placeholder="First Name" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <Field name="l_name">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="text" class="form-control" id="inputlastname" placeholder="Last Name" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <Field name="business_name">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="text" class="form-control" id="inputbname" placeholder="First Name" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <div class="form-group">
                            <input type="text" class="form-control" id="inputemail" placeholder="Email Address" value={currentSeller.email} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Field name="phone">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="tel" class="form-control" id="inputphone" placeholder="phone" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="button-group">
                        <button type="submit" className="sellerpbtn" disabled={btnLoading}>
                          {btnLoading ? "Loading.." : "Edit"}
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

export default ProfileInfo;
