import { CHAT_LIST, SET_SENDER_DETAIL } from "./ChatTypes";

const initialState = {
  chats: [],
  currentChat: {
    messages: [],
    chatId: null,
    chatUsers: [],
    senderDetail: null
  },
};

export default function ChatReducer (state = initialState, action){
    switch (action.type) {
      case CHAT_LIST: {
        return Object.assign({}, state, {
          chats: action.payload
        });
      }
      case SET_SENDER_DETAIL: {
        return Object.assign({}, state, {
          senderDetail: action.payload,
        });
      }

      default:
        return state;
    }
};
