import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/trading-logo.png";
import cartimg from "../../assets/img/cart.png";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ramdomImage } from "../../constant/ConstantFunction";
import { GetAds, GetProductsFilter } from "../../network/Network";
import { Adsapi } from "../../redux/actions/CartActions";
import imageProoo from "../../assets/img/profile-img.png";
import { logout } from "../../redux/actions/SellerAction";
import { SellerImageUrl } from "../../network/ApiUrl";

const SellerHeader = (props) => {
  const { setChecked, checked } = props;
  const dispatch = useDispatch();
  const sellerIsLogin = useSelector((state) => state.SellerReducer.sellerIsLogin)
  const userData = useSelector((state) => state.SellerReducer.sellerUser);
  const CartReduxData = useSelector((state) => state.CartReducer.cartData);
  const CompanyInfoRedux = useSelector((state) => state.AuthReducer.companyInfo);
  const [loading, setLoading] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(logout());
      // Navigate("/Signin");
      window.location.href = "/Signin";
    }, 200);
  };

  let currentUrl = window.location.href.split("/");
  const [type, setType] = useState(currentUrl[3]);
  const [home, setHome] = useState(false);
  const [hot, setHot] = useState(false);
  const [products, setProducts] = useState(false);
  const [faq, setFaq] = useState(false);
  const [contact, setContact] = useState(false);
  const [delivery, setDelivery] = useState(false);


  return (
    <>
      {/* <!-- Navbar home --> */}
      <div className="header-blue">
        <nav className="navbar navbar-dark navbar-expand-md navigation-clean-search">
          <div className="container">
            <Link className="navbar-brand navbar-left" to="/">
              <img width="200" src={CompanyInfoRedux?.web_logo} target="_self" className="logo-width" alt="" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleCollapse}
              // data-toggle="collapse"
              // data-target="#navbarNavDropdown"
              // aria-controls="navbarNavDropdown"
              // aria-expanded="false"
              // aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={"collapse navbar-collapse " + (collapse && "show")} class="d-flex justify-content-between w-100" id="navbarNavDropdown">
              <ul className="nav  navbar-right">
                <li className="nav-item" role="presentation">
                  <Link to="/" className={currentUrl[3] === " " ? "active-nav active" : "nav-link"}>
                    {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="23.332" viewBox="0 0 25.5 28.332">
                      <g id="Group_4116" data-name="Group 4116" transform="translate(-517.7 -51.668)">
                        <g id="bx-home-alt" transform="translate(513.196 48.668)">
                          <path id="Path_84" data-name="Path 84" d="M11.755,21.617a2.835,2.835,0,0,1,2.833-2.833h5.666a2.835,2.835,0,0,1,2.833,2.833V28.7h4.252l0-12.163L17.421,6.621,7.5,16.537V28.7h4.25Z" transform="translate(-0.167 -0.201)" fill="none" />
                          <path id="Path_85" data-name="Path 85" d="M15,22.5h5.666v7.083H15Z" transform="translate(-0.584 -1.084)" fill="none" />
                          <path id="Path_86" data-name="Path 86" d="M7.338,31.332H27.171A2.835,2.835,0,0,0,30,28.5V15.749a1.413,1.413,0,0,0-.416-1L18.255,3.416a1.415,1.415,0,0,0-2,0L4.92,14.748a1.413,1.413,0,0,0-.416,1V28.5a2.835,2.835,0,0,0,2.833,2.833ZM14.421,28.5V21.416h5.666V28.5ZM7.338,16.336,17.254,6.42l9.916,9.916V28.5H22.92V21.416a2.835,2.835,0,0,0-2.833-2.833H14.42a2.835,2.835,0,0,0-2.833,2.833V28.5H7.337V16.336Z" transform="translate(0)" fill="#8dc63f" />
                        </g>
                      </g>
                    </svg>
                  </Link>
                </li>
              </ul>
              <ul className="nav  navbar-right">
                {sellerIsLogin ? (
                  <>
                    <li className="nav-item dropdown">
                      <a
                        // to="/auth/profile"
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <div className="profile-container">
                          {userData?.image == null ? (
                            <>
                              <img src={imageProoo} className="image-width" style={{ borderRadius: "50%" }} />
                            </>
                          ) : (
                            <>
                              <img src={`${SellerImageUrl}/${userData.image}`} alt="" className="image-fluid image-width" />
                            </>
                          )}
                        </div>
                      </a>

                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link className="dropdown-item" to="/seller/auth/profile">
                          {userData?.f_name.substring(0, 8)}
                        </Link>
                        <Link className="dropdown-item" to="/seller/chats">
                          Messages
                        </Link>
                        <a className="dropdown-item">
                          <p className="header-role" onClick={(e) => LogoutHandler(e)} style={{ cursor: "pointer" }}>
                            {loading ? "Loading.." : "Logout"}
                          </p>
                        </a>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item p-2" role="presentation">
                      <Link to="/Signin" className="btn btn-signup">
                        {" "}
                        Sign In
                      </Link>
                    </li>
                    <li className="nav-item p-2" role="presentation">
                      <Link to="/Signup" className="btn btn-signup">
                        {" "}
                        Sign Up
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </div>

      {/* <!-- Navbar home close --> */}
      {/* <nav className="navbar navbar-expand-lg navbar-dark bg-light border-top border-bottom box-shadow-b">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="container">
            <ul className="navbar-nav mr-auto">
              {CategoriesRedux?.map((item, index) => {
                return (
                  <li className="nav-item" key={item?.id}>
                    <Link
                      to={`/Productspage/${item?.slug}`}
                      key={item?.id}
                      className={
                        currentUrl[4] === item?.slug
                          ? "nav-link font-10 active"
                          : "nav-link font-10"
                      }
                    >
                      <img src={item?.image_url} alt="" className="mr-2" />

                      {item?.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav> */}
      <Outlet />
    </>
  );
};

export default SellerHeader;
