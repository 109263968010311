import { CHAT_LIST, SET_SENDER_DETAIL } from "../reducers/Chat/ChatTypes";



export const ChatList = (payload) => {
  return {
    type: CHAT_LIST,
    payload,
  };
};

export const SetSender = (payload) => {
  return {
    type: SET_SENDER_DETAIL,
    payload
  };
}