export const ApiUrl =
	// "http://127.0.0.1:9700/api/v1" ||
	"https://trading-new.developer-ourbase-camp.com/api/v1" ||
	"http://localhost:4000";
export const ImageUrl =
	// "http://127.0.0.1:9700/storage/product" ||
	" http://developer-ourbase-camp.com/trading-new/storage/app/public/product" ||
	"http://localhost:4000";

export const SellerImageUrl =
	// "http://127.0.0.1:9700/storage/seller" ||
	"http://developer-ourbase-camp.com/trading-new/storage/app/public/seller" ||
	"http://localhost:4000";


// export const ApiUrl =
//   "http://192.168.3.96/trading/api/v1" || "http://localhost:4000";
// export const ImageUrl =
//   "http://192.168.3.96/trading" || "http://localhost:4000";
