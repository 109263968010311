import React, { useEffect, useState } from "react";
import "../../../../assets/css/sellerlogin.css";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sellerLogin } from "../../../../redux/actions/SellerAction";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Login = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const sellerIsLogin = useSelector((state) => state.SellerReducer.sellerIsLogin);

  useEffect(() => {
    if (sellerIsLogin) {
      Navigate("/seller/profile");
    }
  }, [sellerIsLogin]);

  return (
    <>
      <div className="sellerlogin_page">
        <Header />
        <section class="signin">
          <div class="container">
            <div class="SigninBox">
              <h2>Login Account</h2>
              <Formik
                initialValues={{ email: "", password: "", remember_me: false }}
                validationSchema={Yup.object({
                  email: Yup.string().email("Invalid email address").required("Required"),
                  password: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                  
                  let x = await dispatch(sellerLogin(values));
                  if (x) {
                    // window.location.href = "/seller/profile";
                    // Navigate("/seller/profile");
                  }
                  // const posted = requestPostFetch(contactApi, values);
                  resetForm({ values: "" });
                }}>
                <Form>
                  <Field name="email">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div class="form-group">
                        <input type="email" id="email" class="form-control" placeholder="Email" {...field} />
                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  <Field name="password">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div class="form-group">
                        <input type="password" id="password" class="form-control" placeholder="Password" {...field} />
                        {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                      </div>
                    )}
                  </Field>
                  <Field name="remember_me">
                    {({
                      field, // { name, value, onChange, onBlur }
                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      meta,
                    }) => (
                      <div className="remember_dv">
                        <div class="form-group">
                          <input type="checkbox" id="cuscheck" class="" placeholder="Password" {...field} />
                          <label for="cuscheck">Remember Me</label>
                        </div>
                        <div className="forgetbtn">
                          <Link to="">Forget Password?</Link>
                        </div>
                      </div>
                    )}
                  </Field>
                  <button type="submit" disabled={loading}>
                    {loading ? "Loading.." : "Login"}
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
