import React, { useEffect, useState } from "react";
import { ImageUrl } from "../../../network/ApiUrl";
import { useDispatch, useSelector } from "react-redux";
import { deleteCartItem, updateCart } from "../../../redux/actions/CartActions";
import { toast } from "react-toastify";

function CartSection() {
  const token = useSelector((state) => state.AuthReducer.token);
  const userRedux = useSelector((state) => state.AuthReducer.users);
  const cartRedux = useSelector((state) => state.CartReducer.cartData);
  const [cartData, setCartData] = useState(cartRedux)
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   setCartData(cartRedux);
  // },[cartRedux]);


  const Increment = (index, error = false, updated = false) => {
    setCartData((prev)=>{
      let temp = [...prev]
      let addUp = updated ? temp[index].quantity : temp[index].quantity + 1;
      if (userRedux?.role !== "Trader" && addUp >= temp[index].productitem.current_stock) {
        toast.error("cannot add more, product is out of stock");
        error = true;
      } else {
        temp[index].quantity = addUp;
        updated = true
      }
      return temp
    });
    dispatch(updateCart(cartData));
  };

  const Decrement = async (index) => {
    await setCartData((prev)=>{
      let temp = [...prev]
      let deductedValue = temp[index].quantity - 1;
      if (userRedux?.role === "Trader" && deductedValue >= temp[index].productitem.trade_qty ) {
        temp[index].quantity = deductedValue !== 0 ? deductedValue : temp[index].quantity;
      } else {
        temp[index].quantity = deductedValue !== 0 ? deductedValue : temp[index].quantity;
      }
      return temp
    });
    dispatch(updateCart(cartData));
  };

  const RemoveCartItem = async (index) => {
    await setCartData((prev)=>{
      let temp = [...prev];
      temp.splice(index, 1);
      dispatch(updateCart(temp));
      return temp
    });
  };

  return ( cartData &&
    <>
      {cartData?.map((item, index) => {
        return (
          <tr className="products cart" key={item?.id}>
            <td className="productDetail">
              <div className="product-detail">
                <div className="imgBox">
                  <figure>
                    <img src={`${item?.productitem?.thumbnail_url}`} alt="" className="image-fluid image-width" />
                  </figure>
                </div>
                <div className="name">
                  <p className="font-13">{item?.productitem?.name}</p>
                </div>
              </div>
            </td>
            <td className="Proprice">
              <h5 className="font-15">{item?.productitem?.unit_price}</h5>
            </td>
            <td className="qty">
              <div>
                <button className="btn increment-Decrement mr-2" onClick={() => Increment(index)}>
                  +
                </button>
                {item.quantity}
                <button className="btn increment-Decrement ml-2" onClick={() => Decrement(index)}>
                  -
                </button>
              </div>
            </td>
            <td className="addcart">
              <div className="ActionBtn">
                <div className="cartBtn">
                  <p>{Number(item?.quantity * item?.productitem?.unit_price).toFixed(2)}</p>
                </div>
                <div className="deleteBtn" onClick={() => RemoveCartItem(index)}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
}

export default CartSection;