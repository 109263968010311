import React, { useState, useEffect } from "react";
import "../../assets/css/hotdeals.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Cards from "../components/Cards";
import Products from "../components/Products";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import Accordians from "../components/Accordian/Accordians";
import { GetAllProducts, GetFilterBrands, GetHomeBanner, GetHotDeals, GetHotProductFilter, PostPriceFilter } from "../../network/Network";
import { SpinnerCircular } from "spinners-react";
import PriceRange from "../components/SideFilter/PriceRange";
import BrandFilter from "../components/SideFilter/BrandFilter";
import ReactPaginate from "react-paginate";
import { HotDealsData } from "../../redux/actions/AuthActions";
import MuiAccordian from "../components/Accordian/MuiAccordian";
import Loader from "../components/Loader/Loader";
import axios from "axios";
import jiggle2 from "../../assets/img/img-left.png";

const Hotdeals = (props) => {
  const dispatch = useDispatch();
  const Categories = useSelector((state) => state.AuthReducer.categoriesData);
  const AdsData = useSelector((state) => state.CartReducer.ads);
  const BrandsDataRedux = useSelector((state) => state.AuthReducer.brands);
  const TopProductsRedux = useSelector((state) => state.CartReducer.topProducts);

  const regularSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };
  // new
  const [checked, setChecked] = useState("");
  const [cateId, setCateId] = useState([]);
  const [brandId, setBrandId] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [NextDataUrl, setNextDataUrl] = useState();
  const [PreDataUrl, setPreDataUrl] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [startPrice, setStartPrice] = useState(1);
  const [endPrice, setEndPrice] = useState(10000);

  const [highToLow, setHighToLow] = useState(null);
  const [productImages, setProductImages] = useState([]);
  const [bannerData, setBannerData] = useState();
  const [loader, setLoader] = useState(true);

  const handleSelectCategory = (id) => {
    setChecked(id);
  };

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const ApiProduct = (data) => {
    GetHotDeals(currentPage, data)
      .then((res) => {
        setProductsData(res?.data?.data?.products?.data);
        setProductImages(res?.data?.data?.products?.data);
        setNextDataUrl(res?.data?.data?.products?.next_page_url);
        setPreDataUrl(res?.data?.data?.products?.prev_page_url);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("err");
        setSpinLoad(false);
      });
  };

  useEffect(() => {
    setSpinLoad(true);
    const data = {};
    ApiProduct(data);
  }, [currentPage]);

  const handleSelectCate = (id, checked) => {
    var categoryExist = cateId.indexOf(id);
    if (categoryExist !== -1) {
      setCateId((prev) => {
        prev.splice(categoryExist, 1);
        return [...prev];
      });
      let data = {
        highToLow: highToLow,
        price_start: startPrice,
        price_end: endPrice,
        category_id: cateId,
        brand_id: brandId,
      };
      ApiProduct(data);
    } else {
      setSpinLoad(true);
      setCateId((prev) => {
        if (prev.indexOf(id) === -1) {
          prev.push(id);
        }
        return prev;
      });
      const data = {
        highToLow: highToLow,
        price_start: startPrice,
        price_end: endPrice,
        category_id: cateId,
        brand_id: brandId,
      };
      ApiProduct(data);
    }
  };

  useEffect(() => {
    setSpinLoad(true);
    let data = {
      highToLow: highToLow,
      price_start: startPrice,
      price_end: endPrice,
      category_id: cateId,
      brand_id: brandId,
    };
    ApiProduct(data);
  }, [cateId]);

  const handleSelectBrand = (id, checked) => {
    var brandExist = brandId.indexOf(id);
    if (brandExist !== -1) {
      setBrandId((prev) => {
        prev.splice(brandExist, 1);
        return prev;
      });
      let data = {
        highToLow: highToLow,
        price_start: startPrice,
        price_end: endPrice,
        category_id: cateId,
        brand_id: brandId,
      };
      ApiProduct(data);
    } else {
      setBrandId((prev) => {
        prev.push(id);
        return prev;
      });
      let data = {
        highToLow: highToLow,
        price_start: startPrice,
        price_end: endPrice,
        category_id: cateId,
        brand_id: brandId,
      };
      ApiProduct(data);
    }
  };

  // Get Filter Brands
  useEffect(() => {
    setSpinLoad(true);
    let data = {
      highToLow: highToLow,
      price_start: startPrice,
      price_end: endPrice,
      category_id: cateId,
      brand_id: brandId,
    };
    ApiProduct(data);
  }, []);

  const PriceFilterHandler = (e) => {
    e.preventDefault();
    setSpinLoad(true);
    let data = {
      highToLow: highToLow,
      price_start: startPrice,
      price_end: endPrice,
      category_id: cateId,
      brand_id: brandId,
    };
    ApiProduct(data);
  };

  const HIghLowFunc = (e) => {
    setHighToLow(e);
     setHighToLow(e);
     setSpinLoad(true);
     let data = {
       highToLow: e,
       price_start: startPrice,
       price_end: endPrice,
       category_id: cateId,
       brand_id: brandId,
     };
    ApiProduct(data);
  };

  useEffect(() => {
    let data = {
      type: "all",
    };
    GetHomeBanner(data)
      .then((res) => {
        let i;
        let arr = [];
        for (i = 0; i < res?.data.length; i++) {
          if (res?.data?.[i].banner_type === "product_page") {
            arr.push(res?.data?.[i]);
          }
        }
        setBannerData(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, [spinLoad]);

  const HandleNext = async () => {
    let next = NextDataUrl;
    let data = await axios.get(next);
    setProductsData(data?.data?.products?.data?.data);
    setNextDataUrl(data?.data?.products?.data?.next_page_url);
    setPreDataUrl(data?.data?.products?.data?.prev_page_url);
  };

  const HandlePre = async () => {
    // if(currentPage => 1){
    let prev = PreDataUrl;
    let data = await axios.get(prev);
    setProductsData(data?.data?.products?.data?.data);
    setPreDataUrl(data?.data?.products?.data?.prev_page_url);
    setNextDataUrl(data?.data?.products?.data?.next_page_url);
    // }
  };

  return (
    <div className="HOT_DEALS_PAGE">
      {!loader ? (
        <>
          <img src={jiggle2} className="img-tls" alt="" />
          <Header setChecked={setChecked} />

          <section className="banner universal-banner">
            <div className="container">
              <div className="breadcrumbs">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hot Deals
                    </li>
                  </ol>
                </nav>
              </div>
              {bannerData?.length >= 0 ? (
                <div className="Banner">
                  <div className="banner-box">
                    <figure>
                      <img src={bannerData[0]?.banner_image_url} alt="" />
                    </figure>
                  </div>
                  <div className="contentDv">
                    <div className="contentFlex">
                      <p className="font-25">{bannerData?.[0]?.sub_title}</p>
                      <h3 className="fw-600 font-80 line-height-50 subtitle">{bannerData?.[0]?.title}</h3>
                      <p>{bannerData?.[0]?.description}</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </section>
          {/* <!-- Banner Section Start Here --> */}

          {/* <!-- Product Slider Start Here --> */}
          <div className="container">
            <div className="row pb-4">
              <div className="col-md-6">
                <h5 className="sub-heading fw-600 font-20">Some Best Products</h5>
              </div>
            </div>
            <div className="regular">
              <Slider {...regularSlider}>
                {TopProductsRedux?.map((item, index) => (
                  <Cards index={index} item={item} />
                ))}
              </Slider>
            </div>
          </div>
          {/* <!-- Product Slider End Here --> */}

          {/* <!-- Products Start Here --> */}
          <section className="hotdeals">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="filters">
                    <div className="filters-head filter-border py-2">
                      <h3 className="font-35 text-black">Apply Filters</h3>
                    </div>
                    <div className="categories">
                      <div className="head py-3">
                        <h4 className="font-25">Categories</h4>
                      </div>

                      <div id="accordion">
                        {Categories?.map((item, index) => {
                          return <MuiAccordian item={item} index={index} handleSelectCategory={handleSelectCategory} handleSelectCate={handleSelectCate} checked={checked} cateId={cateId} />;
                        })}
                      </div>
                    </div>

                    <PriceRange setStartPrice={setStartPrice} startPrice={startPrice} setEndPrice={setEndPrice} endPrice={endPrice} PriceFilterHandler={PriceFilterHandler} />
                    <BrandFilter BrandsDataRedux={BrandsDataRedux} handleSelectCategory={handleSelectCategory} handleSelectBrand={handleSelectBrand} brandId={brandId} />

                    {/* <div className="ads-banner">
                    <figure>
                      <img
                        src={AdsData[1]?.image_url}
                        alt=""
                        className="image-fluid"
                      />
                    </figure>
                  </div> */}
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="topBar">
                    <div className="heading">
                      <h2 className="font-60">Hot Deals</h2>
                    </div>
                    <div className="sort-btn">
                      {!highToLow || highToLow === "low_to_high" ? (
                        <button className="font-20" onClick={() => HIghLowFunc("high_to_low")}>
                          Sort By: High To low
                        </button>
                      ) : (
                        <button className="font-20 ml-3" onClick={() => HIghLowFunc("low_to_high")}>
                          Sort By: Low To High
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {spinLoad ? (
                      <>
                        <div className="loader-container">
                          <SpinnerCircular size="80px" color="#8dc63e" />
                        </div>
                      </>
                    ) : (
                      <>
                        {productsData ? (
                          productsData?.slice(0, 3).map((item, index) => (
                            <div className="col-md-4 pt-2 " key={index}>
                              <Products item={item} index={index} />
                            </div>
                          ))
                        ) : (
                          <p>Product not found</p>
                        )}
                      </>
                    )}
                  </div>

                  <div className="box py-4 mb-3 mt-5">
                    <img
                      src={AdsData[0]?.image_url}
                      className="img-fluid cursor_pointer"
                      alt=""
                      onClick={() => {
                        window.open(`${AdsData[0]?.url}`);
                      }}
                    />
                    <div className=" bottom-left">
                      <p
                        // to={`${AdsData[0]?.url}`}
                        // target="_blank"
                        className="btnbl text-black font-12 d-flex gap-2 align-items-center"
                        tabIndex="-1">
                        {AdsData[0]?.button_name}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
                          <g id="Group_4119" data-name="Group 4119" transform="translate(-336 -1971)">
                            <g id="Group_47" data-name="Group 47" transform="translate(-5 2)">
                              <g id="Ellipse_5" data-name="Ellipse 5" transform="translate(341 1969)" fill="none" stroke="#000" stroke-width="1">
                                <circle cx="15" cy="15" r="15" stroke="none" />
                                <circle cx="15" cy="15" r="14.5" fill="none" />
                              </g>
                              <path id="Icon_awesome-arrow-right" data-name="Icon awesome-arrow-right" d="M.187,3.154l.385-.385a.414.414,0,0,1,.587,0L4.527,6.136a.414.414,0,0,1,0,.587L1.159,10.091a.414.414,0,0,1-.587,0L.187,9.706a.416.416,0,0,1,.007-.594L2.281,7.123H-9.584A.415.415,0,0,1-10,6.707V6.153a.415.415,0,0,1,.416-.416H2.281L.194,3.748A.413.413,0,0,1,.187,3.154Z" transform="translate(358.94 1977.355)" fill="#000" />
                            </g>
                          </g>
                        </svg>
                      </p>
                    </div>

                    <div className="m-centered">
                      <p className="text-black line-height-1 font-25">{AdsData[0]?.title}</p>
                      <h2 className="text-black line-height-1 font-35">{AdsData[0]?.sub_title}</h2>
                    </div>
                  </div>

                  <div className="row ">
                    {spinLoad ? (
                      <>
                        <div className="loader-container">
                          <SpinnerCircular size="80px" color="#8dc63e" />
                        </div>
                      </>
                    ) : (
                      productsData?.slice(2).map((item, index) => (
                        <div className="col-md-4 pt-2 my-3	" key={index}>
                          <Products item={item} index={index} />
                        </div>
                      ))
                    )}
                  </div>

                  <div className="pagination-container mt-5 d-flex flex-row w-100 justify-content-between">
                    {PreDataUrl && (
                      <p className="btn btn-light bt-nxt-allprodcts" onClick={HandlePre}>
                        Previous Page
                      </p>
                    )}

                    {NextDataUrl && (
                      <p onClick={HandleNext} className="btn btn-light bt-nxt-allprodcts">
                        Next Page
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Hotdeals;
