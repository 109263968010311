import Header from "../../components/Header";
import "./chat.css";
import Pusher from "pusher-js";
import List from "../../components/Chat/List";
import Message from "../../components/Chat/Message";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GetAllChat, GetSingleChat } from "../../../network/ChatNetwork";
import { ChatList } from "../../../redux/actions/ChatAction";
import { toast } from "react-toastify";

export default function ChatWrapper({ token, isSeller }) {
  const dispatch = useDispatch();
  let { search, seller } = useParams();
  const senderDetail = useSelector((state) => state.ChatReducer.senderDetail);
  const [searchQuery, setSearchQuery] = useState(search ?? null);
  const [currentChat, setCurrentChat] = useState(null);
  const currentUser = useSelector((state) => state.AuthReducer.users);
  const currentSellerUser = useSelector((state) => state.SellerReducer.sellerUser);
  const apiType = !isSeller ? null : "seller";
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
  });

  useEffect(() => {
    if (currentChat !== null) {
      const channel = pusher.subscribe("chat." + currentChat.id);
      channel.bind("App\\Events\\ChatEvents", (data) => {
        // debugger;
        FetchSingleChat(data.data);
      });
    }

    return () => {
      // if (currentChat !== null) {
      //   pusher.unsubscribe("chat." + currentChat.id);
      // }
    };
  }, [currentChat]);


  /**
   *
   * @param {Array} responseResult
   * @param {Object} chatExist
   * @param {Object} senderDetailPresent
   * @returns
   */
  const setDefaultActiveChat = (chatList, chatExist = null, senderDetailPresent = null) => {
    let currentChat = chatExist !== null ? chatExist : chatList[0];
    let chatObject = {
      id: currentChat?.id ?? null,
      user_detail: isSeller ? currentChat?.user ?? senderDetailPresent : currentChat?.seller ?? senderDetailPresent,
      messages: currentChat?.messages ?? [],
    };
    setCurrentChat((prev) => {
      return chatObject;
    });
    return chatExist === null && senderDetailPresent !== null ? [chatObject, ...chatList] : [...chatList];
  };

  /**
   * This function use to fetch chats
   */
  const FetchChat = () => {
    GetAllChat(token, searchQuery, apiType)
      .then((res) => {
        var responseResult = res?.data?.response?.data;
        var tempChatArray = [];
        if (senderDetail !== null && senderDetail !== undefined && seller !== undefined) {
          if (senderDetail.f_name + " " + senderDetail.l_name !== seller) {
            toast.error("No user found with this " + seller);
            tempChatArray = setDefaultActiveChat(responseResult);
          } else {
            var checkChatExist =
              responseResult.find((chat) => {
                return isSeller ? chat.user.id === senderDetail.id : chat.seller.id === senderDetail.id;
              }) ?? null;
            tempChatArray = setDefaultActiveChat(responseResult, checkChatExist, senderDetail);
          }
        } else {
          tempChatArray = setDefaultActiveChat(responseResult);
        }
        dispatch(ChatList(tempChatArray ?? []));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function use to fetch chat
   */
  const FetchSingleChat = (id) => {
    GetSingleChat(token, id, apiType)
      .then((res) => {
        setDefaultActiveChat([], res.data.response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (token !== '' && token !== null && token !== undefined) FetchChat();
  }, [token, searchQuery, seller]);

  
  const messageProps = {
    currentChat: currentChat,
    currentUser: isSeller ? currentSellerUser : currentUser,
    token: token,
    updateCurrentChat: (chatId) => {
      FetchSingleChat(chatId);
    },
    fetchChatAgain: () => {
        FetchChat()
    },
    userType: isSeller ? "App\\Model\\Seller" : "App\\User",
  };

  return (
    <div className="d-block">
      <div className="container p-0">
        <h1 className="h3 mb-3">Messages</h1>
        <div className="card">
          <div className="row g-0">
            <List currentChat={currentChat} isSeller={isSeller}  updateCurrentChat={(id) => {FetchSingleChat(id)}}/>
            {currentChat != null && currentChat.user_detail != null && <Message {...messageProps} />}
          </div>
        </div>
      </div>
    </div>
  );
}
