// Seller types are mentions below
export const SELLER_SIGNUP = "SELLER_SIGNUP";
export const SELLER_LOGIN = "SELLER_LOGIN";
export const SELLER_LOGOUT = "SELLER_LOGOUT";

export const SELLER_LOGIN_REQUEST = "SELLER_LOGIN_REQUEST";
export const SELLER_LOGIN_SUCCESS = "SELLER_LOGIN_SUCCESS";
export const SELLER_LOGIN_ERROR = "SELLER_LOGIN_ERROR";

export const SELLER_SIGNUP_REQUEST = "SELLER_SIGNUP_REQUEST";
export const SELLER_SIGNUP_SUCCESS = "SELLER_SIGNUP_SUCCESS";
export const SELLER_SIGNUP_ERROR = "SELLER_SIGNUP_ERROR";


export const SELLER_FORGOT_PASSWORD_REQUEST = "SELLER_FORGOT_PASSWORD_REQUEST";
export const SELLER_FORGOT_PASSWORD_SUCCESS = "SELLER_FORGOT_PASSWORD_SUCCESS";
export const SELLER_FORGOT_PASSWORD_ERROR = "SELLER_FORGOT_PASSWORD_ERROR";

export const SELLER_SET_PASSWORD_REQUEST = "SELLER_SET_PASSWORD_REQUEST";
export const SELLER_SET_PASSWORD_SUCCESS = "SELLER_SET_PASSWORD_SUCCESS";
export const SELLER_SET_PASSWORD_ERROR = "SELLER_SET_PASSWORD_ERROR";


export const SELLER_LOGOUT_REQUEST = "SELLER_LOGOUT_REQUEST";
export const SELLER_LOGOUT_SUCCESS = "SELLER_LOGOUT_SUCCESS";
export const SELLER_LOGOUT_ERROR = "SELLER_LOGOUT_ERROR";
export const SELLER_USER_TOKEN = "SELLER_USER_TOKEN";

export const SELLER_EDIT_PROFILE = "SELLER_EDIT_PROFILE";

// order's type below
export const SELLER_ALL_ORDERS = "SELLER_ALL_ORDERS";

// Product's type below
export const SELLER_SET_PRODUCT_DETAIL = "SELLER_SET_PRODUCT_DETAIL";