import React, { useRef } from "react";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../../assets/css/sellersidebar.css";
import userimg from "../../../assets/img/userimg.png";
import notificationimg from "../../../assets/img/notificationimg.png";
import "../../../assets/css/sellernotification.css";
import { useDispatch, useSelector } from "react-redux";
import { SellerImageUrl } from "../../../network/ApiUrl";
import { EditProfileData, logout } from "../../../redux/actions/SellerAction";
import { GetAllNotifications, MarkNotification, MarkNotificationBulk, UpdateProfile } from "../../../network/SellerNetwork";
import { useEffect } from "react";
import { toast } from "react-toastify";
import imageProoo from "../../../assets/img/profile-img.png";

const SellerSideBar = () => {
  const user = useSelector((state) => state.SellerReducer.sellerUser);
  const dispatch = useDispatch();
  const [newFile, setNewFile] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [notifications, setNotifications] = useState([]);
  const imageRef = useRef(null);
  const notiBtn = useRef(null);

  // Get All orders
  useEffect(() => {
    GetAllNotifications(token)
      .then((res) => {
        setNotifications(res?.data?.data?.notifications ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const updateReadOnAll = () => {
    const data =
      notifications
        .filter((x) => x.read === 0)
        .map((x) => {
          return x.id;
        }) ?? [];
    MarkNotificationBulk(data, token)
      .then((res) => {
        // Navigate("/seller/order-detail/" + data);
      })
      .catch((err) => {
        // toast.error(err?.response?.data?.payload?.message);
      });
  };

  const handleImageInputChange = async (element) => {
    var files = element.dataTransfer === undefined ? element.target.files : element.dataTransfer.files;
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name.split(".");
      const extension = fileName[fileName.length - 1];
      const allowedTypes = ["jpg", "jpeg", "png"];
      if (!allowedTypes.includes(extension.toLowerCase())) {
        alert("Your file format is not supported");
        files = [];
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      setImagePreview(URL.createObjectURL(files[i]));
      let data = new FormData();
      data.append("f_name", user.f_name);
      data.append("l_name", user.l_name);
      data.append("phone", user.phone);
      data.append("email", user.email);
      data.append("image", files[i]);
      let x = await dispatch(EditProfileData(data, token));
      setImagePreview(null);
    }
  };

  return (
    <>
      <div className="sidebar_sec">
        <div className="john-dow-all">
          <div className="john-img">
            {imagePreview !== null ? <img className="img-fluid" src={imagePreview} alt="" /> : user.image != undefined && user.image !== null ? <img className="img-fluid" src={`${SellerImageUrl}/${user.image}`} alt="" /> : <img className="img-fluid" src={imageProoo} alt="" />}
            <span
              className="addimageicon"
              onClick={() => {
                imageRef.current.click();
              }}>
              <i className="fa fa-plus" aria-hidden="true"></i>
            </span>
            <input ref={imageRef} onChange={(e) => handleImageInputChange(e)} id="uploadfile" type="file"></input>
          </div>
          <div className="john-text">
            <h3>
              {user.f_name} {user.l_name}
            </h3>
          </div>
        </div>
        <div className="twolinks">
          <Link to="/seller/profile">
            <button to="#" className="sidebarbtn">
              <i className="fa fa-angle-right" aria-hidden="true"></i> Personal Info{" "}
            </button>
          </Link>
          <Link to="/seller/change-password">
            <button to="#" className="sidebarbtn">
              <i className="fa fa-angle-right" aria-hidden="true"></i> Change Password
            </button>
          </Link>
          <Link to="/seller/ProductInventory">
            <button to="#" className="sidebarbtn">
              <i className="fa fa-angle-right" aria-hidden="true"></i> Product Inventory
            </button>
          </Link>
          <Link to="/seller/tradingproduct">
            <button to="#" className="sidebarbtn">
              <i className="fa fa-angle-right" aria-hidden="true"></i> Trading Products
            </button>
          </Link>
          <Link to="/seller/order">
            <button to="#" className="sidebarbtn">
              <i className="fa fa-angle-right" aria-hidden="true"></i> My Orders
            </button>
          </Link>
          <Link to="#">
            <button
              to="#"
              ref={notiBtn}
              onClick={() => {
                updateReadOnAll();
              }}
              className="sidebarbtn"
              data-toggle="modal"
              data-target="#notificationmodal">
              <i className="fa fa-angle-right" aria-hidden="true"></i> Notifications
            </button>
          </Link>
        </div>
      </div>

      <div className="modal fade" id="notificationmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <h3>Notifications</h3>
          <div className="modal-content">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              {notifications.length > 0 && <Notification notifications={notifications} notiBtn={notiBtn} token={token} />}
              {notifications.length === 0 && (
                <div className="notification_page">
                  <div className="container">
                    <h4>No notifications found</h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Notification = ({ notifications, token, notiBtn }) => {
  const Navigate = useNavigate();

  const handleClickNotification = (notiId, modalId, type) => {
    MarkNotification(notiId, token)
      .then((res) => {
        notiBtn.current.click();
        if (type.includes('Order')) Navigate("/seller/order-detail/" + modalId);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.payload?.message);
      });
  };

  return notifications.map((noti, index) => {
    let date = new Date(noti.created_at).toDateString().split(" ");
    var timeDate = new Date(noti.created_at);
    var notiTime = new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(timeDate);
    return (
      <div key={index} className="notification_page">
        <div className="container">
          <div className="notification_card">
            <div className={noti.read === 0 ? "notification_content active" : "notification_content"} onClick={() => handleClickNotification(noti.id, noti.modalable_id, noti.modalable_type)}>
              <div className="notication_img">
                <img className="img-fluid" src={notificationimg}></img>
                <p>{noti.title}</p>
              </div>
              <div className="notification_para">
                <p>{noti.description}</p>
                <span>
                  {date[2]} {date[1]} {notiTime} | {date[3]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default SellerSideBar;
