import React, { useEffect } from "react";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@popperjs/core";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/st-style.css";
import "./assets/css/style.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Signin from "./views/pages/Signin";
import Signup from "./views/pages/Signup";
// import Refundpolicy from "./views/pages/Policy Pages/Refundpolicy";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAds, GetAllBrands, GetAllCategories, GetCountryApi, GetHotDeals, GetWishList, ProductDetails, TopProducts, SingleBlog, GetColors } from "./network/Network";
import { BrandsFilterStore, CategoriesData, Colors, Country, HotDealsData, WishListData } from "./redux/actions/AuthActions";
import "./assets/css/Custom.css";
import SellerProtectedRoutes from "./routes/SellerProtectedRoutes";
import ForgotEmail from "./views/pages/ForgotEmail";
import ForgotPassword from "./views/pages/ForgotPassword";
import SellerComponent from "./views/pages/seller/auth/Signup";
import SellerProfile from "./views/pages/seller/SellerProfile";
import ProductInventory from "./views/pages/seller/ProductInventory";
import SellerSideBar from "./views/pages/seller/SellerSideBar";
import SellerProductDetail from "./views/pages/seller/SellerProductDetail";
import EditProfileDetail from "./views/pages/seller/EditProfileDetail";
import AddNewProduct from "./views/pages/seller/Product/AddNewProduct";
import TradingProduct from "./views/pages/seller/TradingProduct";
import MyOrder from "./views/pages/seller/MyOrder";
import MyOrderDetail from "./views/pages/seller/MyOrderDetail";
import Notification from "./views/pages/seller/Notification";
import ProfileInfo from "./views/pages/seller/ProfileInfo";
import ChangePassword from "./views/pages/seller/ChangePassword";
import Login from "./views/pages/seller/auth/Login";
import Chat from "./views/pages/Chat/Chat";
import Reviews from "./views/pages/seller/review";
import Home from "./views/pages/Home";
import Contact from "./views/pages/Contact";
import Hotdeals from "./views/pages/Hotdeals";
import Productspage from "./views/pages/Productspage";
import Deliveryinformation from "./views/pages/Deliveryinformation";
import Productdetail from "./views/pages/Productdetail";
import Productcart from "./views/pages/Productcart";
import Checkout from "./views/pages/Checkout";
import Traders from "./views/pages/Traders";
import Refundpolicy from "./views/pages/Policy Pages/Refundpolicy";
import PrivacyPolicy from "./views/pages/Policy Pages/PrivacyPolicy";
import CookiePolicy from "./views/pages/Policy Pages/CookiePolicy";
import TermsCondition from "./views/pages/Policy Pages/Terms";
import Thankyou from "./views/pages/Thankyou";
import Faqs from "./views/pages/FAQs/Faqs";
import AllProducts from "./views/pages/Products/AllProducts";
import Blog from "./views/pages/Blog";
import BlogDetails from "./views/pages/Blog/BlogDetail";

function SellerApp() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);

  // Get All Categories
  useEffect(() => {
    GetAllCategories()
      .then((res) => {
        dispatch(CategoriesData(res?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get All Categories
  useEffect(() => {
    GetAllCategories()
      .then((res) => {
        dispatch(CategoriesData(res?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get All Colors
  useEffect(() => {
    GetColors()
      .then((res) => {
        dispatch(Colors(res?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          {/* <Route exact path="/Bookmark" element={<Bookmark />} /> */}
          <Route path="/Hotdeals" element={<Hotdeals />} />
          <Route path="/Delivery" element={<Deliveryinformation />} />
          <Route exact path="/Productspage/:Id" element={<Productspage />} />
          {/* <Route exact path="/Gaming-deals" element={<Gamingdeals />} /> */}
          <Route exact path="/Product-detail/:Id" element={<Productdetail />} />
          <Route path="Product-cart" element={<Productcart />} />
          <Route exact path="/Checkout" element={<Checkout />} />
          <Route exact path="/traders" element={<Traders />} />
          <Route exact path="/refundpolicy" element={<Refundpolicy />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/cookiepolicy" element={<CookiePolicy />} />
          <Route exact path="/termsandcondition" element={<TermsCondition />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="faqs" element={<Faqs />} />
          <Route exact path="/allproducts" element={<AllProducts />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/:id" element={<BlogDetails />} />
          
          <Route path="*" element={<ProfileInfo />} />
          <Route path="forgotpassword" element={<ForgotEmail />} />
          <Route path="newpassword" element={<ForgotPassword />} />
          {/* seller pages start here */}
          <Route path="sellersignup" element={<SellerComponent />} />
          <Route path="sellerlogin" element={<Login />} />

          <Route path="seller" element={<SellerProtectedRoutes />}>
            <Route path="profile" element={<ProfileInfo />} />
            <Route path="ProductInventory" element={<ProductInventory />} />
            <Route path="SideBar" element={<SellerSideBar />} />
            <Route path="productdetail/:product_id" element={<SellerProductDetail />} />
            <Route path="editprofiledetail" element={<EditProfileDetail />} />
            <Route path="addnewproduct" element={<AddNewProduct />} />
            <Route path="tradingproduct" element={<TradingProduct />} />
            <Route path="order" element={<MyOrder />} />
            <Route path="order-detail/:order_id" element={<MyOrderDetail />} />
            <Route path="notification" element={<Notification />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="review/:product/:page" element={<Reviews />} />

            <Route path="chats" element={<Chat />} />
            <Route path="chats/:seller" element={<Chat />} />
            <Route path="chats/:seller/:search" element={<Chat />} />
          </Route>
          {/* seller pages end here */}
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      {/* <Alert /> */}
    </>
  );
}

export default SellerApp;
