import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import SellerHeader from "../../components/SellerHeader";
import "../../../assets/css/productinventory.css";
import sellerphone from "../../../assets/img/sellerphone.png";
import SellerFooter from "../../components/SellerFooter";
import SellerSideBar from "./SellerSideBar";
import { GetTradingProducts } from "../../../network/SellerNetwork";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { ImageUrl } from "../../../network/ApiUrl";
import { addProductBulk, deleteProduct, deleteProducts } from "../../../redux/actions/SellerAction";
const TradingProduct = () => {
  let { search, page, order } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(page ?? 1);
  const [searchQuery, setSearchQuery] = useState(search ?? null);
  const [arrangement, setArrangement] = useState(order ?? null);
  const [products, setProducts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [checkProductList, setCheckProductList] = useState([]);
  const dispatch = useDispatch();
  const excelRef = useRef(null);
  const location = useLocation();

  const reload = () => {
    navigate(location.pathname);
  };

  // Get All products
  useEffect(() => {
    if (currentPage !== undefined) {
      GetTradingProducts(currentPage, token, searchQuery, arrangement)
        .then((res) => {
          setProducts(res?.data?.data?.products);
          const total = res?.data?.data?.total_size;
          setPageCount(Math.ceil(total / 10));
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentPage, searchQuery, arrangement]);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const handleSelect = (e, productId) => {
    setCheckProductList((prev) => {
      return [...prev, productId];
    });
  };

  const handleBulkDelete = async () => {
    let x = await dispatch(deleteProducts({ ids: checkProductList }, token));
    if (x) {
      window.location.href = "/seller/tradingproduct";
    }
  };

  const handleDelete = async (id) => {
    let x = await dispatch(deleteProduct({ id: id }, token));
    if (x) {
      window.location.href = "/seller/tradingproduct";
    }
  };

  const handleExcelInputChange = async (element) => {
    var files = element.dataTransfer === undefined ? element.target.files : element.dataTransfer.files;
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name.split(".");
      const extension = fileName[fileName.length - 1];
      const allowedTypes = ["xlsx", "csv"];
      if (!allowedTypes.includes(extension.toLowerCase())) {
        alert("Your file format is not supported");
        files = [];
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      let data = new FormData();
      data.append("products_file", files[i]);
      let x = await dispatch(addProductBulk(data, token));
      if (x) {
        reload();
      }
    }
  };

  return (
    <>
      <div className="seller_prodinv_page">
        <SellerHeader />
        <div className="container-fluid">
          <div className="seller_prodinv_sec">
            <div>
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <div className="totalcard">
                  <div className="form-head">
                    <div className="seller_head">
                      <div className="">
                        <h3>Trading Products</h3>
                      </div>
                      <div className="search_sec">
                        <div className="searchandsort form-group">
                          <input
                            type="search"
                            name="search"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                            }}
                            placeholder="Search"
                            className="form-control"
                          />
                        </div>
                        <div className="searchandsort form-group">
                          <input
                            type="search"
                            name="search"
                            value={arrangement}
                            onChange={(e) => {
                              setArrangement(e.target.value);
                            }}
                            placeholder="Sort By   |  All"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-table-wrapper table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className="row1">
                          <th className="order-no">Product Name</th>
                          <th className="order-date">Price</th>
                          <th className="order-status">Stock</th>
                          <th className="order-total">Reviews</th>
                          <th className="details">Status</th>
                          <th className="details">ACTION</th>
                        </tr>
                        {products &&
                          products.map((product, index) => {
                            return (
                              <tr key={index} className="row2">
                                <td className="pname">
                                  <input type="checkbox" onChange={(e) => handleSelect(e, product.id)} checked={checkProductList.includes(product.id)} />
                                  <div className="prodinv_phone">
                                    <img src={`${ImageUrl}/thumbnail/${product.thumbnail}`} className="img-fluid" alt="" />
                                  </div>
                                  {product.name}
                                </td>
                                <td className="pprice"> {product.unit_price}</td>
                                <td className="pstock"> {product.current_stock}</td>

                                <td className="pstar">
                                  <i className="fa fa-star"></i> {product.review}
                                </td>
                                <td className="">
                                  <StatusSpan status={product.status} />
                                </td>
                                <td className="actions">
                                  <div className="seller_actions">
                                    <i className="fa fa-star" onClick={() => navigate("/seller/review/" + product.id + "/" + 1)}></i>
                                    <i className="fa fa-pencil" onClick={() => navigate("/seller/productdetail/" + product.id)}></i>
                                    <i className="fa fa-times" aria-hidden="true" onClick={() => handleDelete(product.id)}></i>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="prodinv_bottom">
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <span>Upload Products in Bulk</span>
                        <span
                          className="greenbtn"
                          onClick={() => {
                            excelRef.current.click();
                          }}>
                          Upload XLS File
                        </span>
                        <input ref={excelRef} onChange={(e) => handleExcelInputChange(e)} id="uploadfile" type="file"></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-right">
                        <span className="greenbtn" onClick={() => navigate("/seller/addnewproduct?is_trade=1")}>
                          Add New Product
                        </span>
                        <span
                          className="deltebtn"
                          disabled={checkProductList.length === 0}
                          onClick={() => {
                            handleBulkDelete();
                          }}>
                          Delete Product
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container mt-5">
              <ReactPaginate previousLabel="<" nextLabel=" >" breakLabel={"..."} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handlePageClick} containerClassName={"pagination justify-content-center"} pageClassName={"page-item"} pageLinkClassName={"page-link"} previousClassName={"page-item"} previousLinkClassName={"page-link"} nextClassName={"page-item"} nextLinkClassName={"page-link"} breakClassName={"page-item"} breakLinkClassName={"page-link"} activeClassName={"active"} />
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

const StatusSpan = ({ status = null }) => {
  switch (status) {
    case "completed":
      return <button className="active">{status}</button>;
    case "delivered":
      return <button className="active">{status}</button>;
    case "pending":
      return <button className="inactive">{status}</button>;
    case "cancelled":
      return <button className="inactive">{status}</button>;
    case "shipping":
      return <button className="shipping">{status}</button>;
    case 1:
      return <button className="active">Active</button>;
    case 0:
      return <button className="active">Inactive</button>;
    default:
      return <button className="processing">{status}</button>;
  }
};

export default TradingProduct;
