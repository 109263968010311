import { useSelector } from "react-redux";
import { SellerImageUrl } from "../../../network/ApiUrl";
import imageProoo from "../../../assets/img/profile-img.png";

export default function List({ currentChat, isSeller, updateCurrentChat }) {
  const chats = useSelector((state) => state.ChatReducer.chats);
  const currentUser = useSelector((state) => state.AuthReducer.users);

  return (
    <div className="col-12 col-lg-5 col-xl-3 border-right chat-section">
      <div className="px-4 d-none d-md-block">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input type="text" className="form-control my-3" placeholder="Search..." />
          </div>
        </div>
      </div>
      {chats.length === 0 && <div class="d-flex mb-2 justify-content-center font-weight-bold">You have not started any chats</div>}

      {chats.map((chat, index) => {
        var chatUser = isSeller ? chat.user : chat.seller;
        var chatUnseenMesg = isSeller ? chat.unSeenMessageSeller : chat.unSeenMessageUser;
        return (
          <a
            href="#"
            onClick={() => {
              updateCurrentChat(chat.id);
            }}
            key={index}
            class={chat.id == currentChat?.id ? "list-group-item list-group-item-action border-0 active" : "list-group-item list-group-item-action border-0"}>
            {chatUnseenMesg > 0 && <div className="badge bg-success float-right">{chatUnseenMesg}</div>}
            <div className="d-flex align-items-start">
              {chatUser?.image == null ? (
                <>
                  <img src={imageProoo} className="rounded-circle mr-1" alt="Vanessa Tucker" width={40} height={40} />
                </>
              ) : (
                <>
                  <img src={(isSeller !== true ? `${SellerImageUrl}/${chatUser?.image}` : `${chatUser?.profile_url}`)} className="rounded-circle mr-1" alt="Vanessa Tucker" width={40} height={40} />
                </>
              )}
              <div className="flex-grow-1 ml-3">
                {chatUser?.f_name ?? ""}
                <div className="small">
                  <span className="fa fa-circle chat-offline" /> {chat?.message?.message ?? ""}
                </div>
              </div>
            </div>
          </a>
        );
      })}
      <hr className="d-block d-lg-none mt-1 mb-0" />
    </div>
  );
}
