import { Field, useField, useFormikContext } from "formik";
import Select, { StylesConfig } from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import chroma from "chroma-js";
import CreatableSelect from "react-select/creatable";
import { useEffect, useRef, useState } from "react";
import UploadImg from "../assets/img/uploadimg.png";
import { ImageUrl } from "../network/ApiUrl";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const colorVal = data.hash ?? data.label;
    const color = chroma(colorVal);
    return {
      ...styles,
      backgroundColor: isDisabled ? undefined : isSelected ? colorVal : isFocused ? color.alpha(1).css() : undefined,
      color: isDisabled ? "#ccc" : isSelected ? (chroma.contrast(color, "white") > 2 ? "white" : "black") : colorVal,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled ? (isSelected ? colorVal : color.alpha(1).css()) : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const colorVal = data.hash ?? data.label;
    const color = chroma(colorVal);
    return {
      ...styles,
      backgroundColor: color.alpha(1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "black",
    ":hover": {
      backgroundColor: data.hash ?? data.label,
      color: "black",
    },
  }),
};

export const toFormData = (o) => {
  return Object.entries(o).reduce((d, e) => (d.append(...e), d), new FormData());
};

export const ImageWrapper = ({ fieldName, label = "", multi = false, path = "/", additionalClass = "", defaultValue = [], form = true, callBack = () => {}, allowedTypes = ["jpg", "jpeg", "png"] }) => {
  const [images, setImages] = useState([]);
  const editImageRef = useRef(null);
  const [editImageIndex, setEditImageIndex] = useState(null);
  const { setFieldValue } = useFormikContext();

  const editImageInput = (index) => {
    setEditImageIndex(index);
    editImageRef.current.click();
  };

  useEffect(() => {
    if (defaultValue.length > 0) {
      setImages(defaultValue);
    }
  },[]);

  useEffect(() => {
    if (form) {
      setFieldValue(fieldName, images);
    }
  }, [fieldName, images, setFieldValue, form]);

  /**
   * This function use to remove image from array on the basis of index
   *
   *
   * @param {Number} index
   */
  const deleteImageInput = (index) => {
    let tempArray = images;
    tempArray.splice(index, 1);
    setImages((prev) => {
      if (form) {
        setFieldValue(fieldName, [...tempArray]);
      }
      return [...tempArray];
    });
  };

  /**
   * This function use to handle images single or mulitple in an array from input field
   *
   * @param {HTMLElement} element
   * @returns
   */
  const handleImageInputChange = (element) => {
    var files = element.dataTransfer === undefined ? element.target.files : element.dataTransfer.files;
    var tempImages = [];
    for (var i = 0; i < files.length; i++) {
      const fileName = files[i].name.split(".");
      const extension = fileName[fileName.length - 1];
      if (!allowedTypes.includes(extension.toLowerCase())) {
        alert("Your file format is not supported");
        files = [];
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);

      tempImages.push({
        image: URL.createObjectURL(files[i]),
        file: files[i],
        alt: files[i].name,
      });
    }

    if (editImageIndex != null) {
      setImages((prev) => {
        prev[editImageIndex] = { ...tempImages[0] };
        setEditImageIndex(null);
        return [...prev];
      });
    } else {
      setImages((prev) => {
        return [...prev, ...tempImages];
      });
    }
  };

  if (form) {
    return (
      <Field name={fieldName}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <>
            <div class="d-flex py-2">
              {images.map((image, index) => {
                const saveUrl = ImageUrl + path + image.image;
                const imageUrl = image.file === undefined ? saveUrl : image.image;
                return (
                  <div key={index}>
                    <label>
                      <img src={imageUrl} alt="" className="img-fluid" />
                    </label>
                    <div class="d-flex justify-content-between px-2">
                      <button type="button" onClick={() => editImageInput(index)}>
                        Edit
                      </button>
                      {multi && (
                        <button type="button" onClick={() => deleteImageInput(index)}>
                          X
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <input
                ref={editImageRef}
                id={fieldName + "_edit_file"}
                onChange={(e) => {
                  handleImageInputChange(e);
                }}
                class="uploadfile"
                type="file"
              />
              {(multi || images.length === 0) && (
                <>
                  <label for={fieldName + "_file"}>
                    <img src={UploadImg} alt="" className="img-fluid" />
                  </label>
                  <input
                    id={fieldName + "_file"}
                    onChange={(e) => {
                      handleImageInputChange(e);
                    }}
                    multiple={multi}
                    class="uploadfile"
                    type="file"
                  />
                </>
              )}
            </div>
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
          </>
        )}
      </Field>
    );
  }

  return (
    <>
      <div class="d-flex py-2">
        {images.map((image, index) => {
          const saveUrl = ImageUrl + path + image.image;
          const imageUrl = image.file === undefined ? saveUrl : image.image;
          return (
            <div key={index}>
              <label>
                <img src={imageUrl.image} alt="" className="img-fluid" />
              </label>
              <div class="d-flex justify-content-between px-2">
                <button type="button" onClick={() => editImageInput(index)}>
                  Edit
                </button>
                {multi && (
                  <button type="button" onClick={() => deleteImageInput(index)}>
                    X
                  </button>
                )}
              </div>
            </div>
          );
        })}
        <input
          ref={editImageRef}
          id={fieldName + "_edit_file"}
          onChange={(e) => {
            handleImageInputChange(e);
          }}
          class="uploadfile"
          type="file"
        />

        {(multi || images.length === 0)(
          <>
            <label for={fieldName + "_file"}>
              <img src={UploadImg} alt="" className="img-fluid" />
            </label>
            <input
              id={fieldName + "_file"}
              onChange={(e) => {
                handleImageInputChange(e);
              }}
              multiple={multi}
              class="uploadfile"
              type="file"
            />
          </>
        )}
      </div>
    </>
  );
};

export const TextAreaWrapper = ({ fieldName, label = "", additionalClass = "", defaultValue = "", form = true, callBack = () => {} }) => {
  const { setFieldValue } = useFormikContext();
  const [textField, setTextField] = useState(defaultValue);
  const handleChange = (element) => {
    if (form) {
      setFieldValue(fieldName, element.target.value);
    } else {
      setTextField(element.target.value);
    }
    callBack(element);
  };
  if (form) {
    return (
      <Field name={fieldName}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <div>
            <textarea
              class={"form-control " + additionalClass}
              id={"textArea" + fieldName}
              placeholder={"Enter " + label}
              {...field}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
          </div>
        )}
      </Field>
    );
  }

  return (
    <div>
      <textarea
        type="text"
        class={"form-control " + additionalClass}
        id={fieldName}
        placeholder={"Enter " + label}
        onChange={(e) => {
          handleChange(e);
        }}>
        {textField}
      </textarea>
    </div>
  );
};

export const TextWrapper = ({ fieldName, label = "", additionalClass = "", defaultValue = "", form = true, callBack = () => {} }) => {
  const { setFieldValue } = useFormikContext();
  const [textField, setTextField] = useState('');
  
  useEffect(() => {
    setTextField(defaultValue);
  }, [defaultValue]);

  const handleChange = (element) => {
    if (form) {
      setFieldValue(fieldName, element.target.value);
    } else {
      setTextField(element.target.value);
    }
    callBack(element);
  };
  if (form) {
    return (
      <Field name={fieldName}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <div>
            <input
              type="text"
              class={"form-control " + additionalClass}
              id={fieldName}
              placeholder={"Enter " + label}
              {...field}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
          </div>
        )}
      </Field>
    );
  }

  return (
    <div>
      <input
        type="text"
        class={"form-control " + additionalClass}
        id={fieldName}
        placeholder={"Enter " + label}
        value={textField}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </div>
  );
};

export const NumberWrapper = ({ fieldName, label = "", defaultValue = 0, additionalClass = "", form = true, callBack = () => {} }) => {
  const { setFieldValue } = useFormikContext();
  const [numberField, setNumberField] = useState(0);

  const handleChange = (element) => {
    if (form) {
      setFieldValue(fieldName, element.target.value);
    }
    setNumberField(element.target.value);
    callBack(element);
  };
  useEffect(() => {
    if (form) {
      setFieldValue(fieldName, defaultValue);
    }
    setNumberField(defaultValue);
  }, [defaultValue]);

  if (form) {
    return (
      <Field name={fieldName}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <div>
            <input
              type="number"
              class={"form-control " + additionalClass}
              id={fieldName}
              placeholder={"Enter " + label}
              {...field}
              value={numberField}
              onChange={(e) => {
                handleChange(e);
              }}
            />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
          </div>
        )}
      </Field>
    );
  }

  return (
    <div>
      <input
        type="number"
        class={"form-control " + additionalClass}
        id={fieldName}
        placeholder={"Enter " + label}
        value={numberField}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    </div>
  );
};

export const SwitchWrapper = ({ fieldName, defaultValue = false, label, callBack = () => {} }) => {
  const { setFieldValue } = useFormikContext();
  const [switchVal, setSwitchVal] = useState(defaultValue);

  useEffect(()=> {
    callBack(defaultValue);
  },[])

  const handleChange = (e) => {
    setFieldValue(fieldName, e.target.checked);
    setSwitchVal(e.target.checked);
    callBack(e.target.checked);
  };
  
  return (
    <Field name={fieldName}>
      {({
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input type="checkbox" value={switchVal} checked={switchVal} class="custom-control-input" id={fieldName} onChange={handleChange} />
            <label class="custom-control-label" for={fieldName}>
              {label}
            </label>
          </div>
          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </div>
      )}
    </Field>
  );
};

export const CkEditorWrapper = ({ fieldName, defaultValue = "" }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={fieldName}>
      {({
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <div class="form-group">
          <CKEditor
            editor={ClassicEditor}
            data={defaultValue}
            onChange={(event, editor) => {
              const data = editor.getData();
              setFieldValue(fieldName, data);
            }}
          />
        </div>
      )}
    </Field>
  );
};

export const CheckBoxWrapper = ({ fieldName, defaultValue = false, labelText = "" }) => {
  const { setFieldValue } = useFormikContext();
  const [checkMark, setCheckMark] = useState(defaultValue);

  const handleCheckedMark = (e) => {
    setCheckMark(e.target.checked);
    setFieldValue(fieldName, e.target.checked);
  }

  return (
    <Field name={fieldName}>
      {({
        field,
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <>
          <input type="checkbox" id={fieldName} class="mr-2" placeholder="Password" checked={checkMark} onChange={(e) => handleCheckedMark(e)} value={checkMark} />
          <label for={fieldName}>{labelText}</label>
          {meta.touched && meta.error && <div className="error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
};

export const SelectWrapper = ({ create = false, defaultValue = {}, form = true, color = false, options, fieldName, multi = false, callBack = () => {} }) => {
  const { setFieldValue } = useFormikContext();
  const selectRef = useRef(null)
  useEffect(()=>{ 
    if(selectRef.current !== null){
      var currentVal = selectRef.current.getValue();
      if ((currentVal.length > 0 && Object.keys(currentVal[0]).length === 0) || currentVal.length === 0) {
        if(Object.keys(defaultValue).length > 0 ){
          selectRef.current.setValue(defaultValue, "select-option");
        }
      } 
    }
  },[defaultValue])

  const handleChange = (element) => {
    if (form) {
      setFieldValue(fieldName, multi ? element : element.value);
    }
    callBack(multi ? element : element.value);
  };
  var colorStyle = color ? { styles: colourStyles } : {};
  const SelectProps = {
    options: options,
    defaultValue: Object.keys(defaultValue).length > 0 ? defaultValue : "",
    isMulti: multi,
    onChange: (e) => {
      handleChange(e);
    },
    ...colorStyle,
  };

  if (form) {
    if (create) {
      return (
        <Field name={fieldName}>
          {({
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div class="form-group">
              <CreatableSelect ref={selectRef} isClearable {...SelectProps} />
              {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            </div>
          )}
        </Field>
      );
    }

    return (
      <Field name={fieldName}>
        {({
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <div class="form-group">
            <Select ref={selectRef} {...SelectProps} />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
          </div>
        )}
      </Field>
    );
  }

  if (create) {
    return (
      <div class="form-group">
        <CreatableSelect ref={selectRef} isClearable {...SelectProps} />
      </div>
    );
  }

  return <Select ref={selectRef} {...SelectProps} />;
};
