import {
  ADD_TO_CART,
  CART_CLEAR,
  DELETE_CART_ITEM,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  PRODUCT_REVIEWS,
  ADS,
  TOP_PRODUCTS,
  COUPON_APPLY,
  COUPON_REMOVE,
  UPDATE_CART,
  TRADE_PRODUCTS,
} from "../Types";

const initialState = {
  cartData: [],
  reviewData: [],
  ads: [],
  coupon: {},
  topProducts: [],
  tradeProducts: [],
};
const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      return {
        ...state,
        cartData: [...state.cartData, action.payload],
      };
    }
    case CART_CLEAR:
      return Object.assign({}, state, {
        cartData: [],
      });
    case UPDATE_CART: {
      return Object.assign({}, state, {
        cartData: action.payload,
      });
    }

    case COUPON_APPLY: {
      return Object.assign({}, state, {
        coupon: action.payload,
      });
    }
    case COUPON_REMOVE: {
      return Object.assign({}, state, {
        coupon: {},
      });
    }

    case PRODUCT_REVIEWS: {
      return Object.assign({}, state, {
        ...state,
        reviewData: action.payload,
      });
    }
    case ADS: {
      return Object.assign({}, state, {
        ...state,
        ads: action.payload,
      });
    }

    case TOP_PRODUCTS: {
      return Object.assign({}, state, {
        ...state,
        topProducts: action.payload,
      });
    }

    case TRADE_PRODUCTS: {
      return Object.assign({}, state, {
        ...state,
        tradeProducts: action.payload,
      });
    }

    default:
      return state;
  }
};
export default CartReducer;
