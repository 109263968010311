import Header from "../../components/Header";
import List from "../../components/Chat/List";
import Message from "../../components/Chat/Message";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GetAllChat, GetSingleChat } from "../../../network/ChatNetwork";
import { ChatList } from "../../../redux/actions/ChatAction";
import { toast } from "react-toastify";
import ChatWrapper from "../../components/Chat/ChatWrapper";
import Footer from "../../components/Footer";
import SellerHeader from "../../components/SellerHeader";
import SellerSideBar from "../seller/SellerSideBar";

const Chat = () => {
  const isSeller = useSelector((state) => state.SellerReducer.sellerIsLogin);
  const sellerToken = useSelector((state) => state.SellerReducer.sellerToken);
  const userToken = useSelector((state) => state.AuthReducer.token);
  const [token, setToken] = useState("");
  useEffect(() => {
    if (isSeller) {
      setToken(sellerToken);
    } else {
      setToken(userToken);
    }
  }, [isSeller]);

  if(isSeller){
    return (
      <>
        <div className="seller_prodinv_page myorder_page">
          <SellerHeader />
          <div className="container-fluid">
            <div className="seller_prodinv_sec">
              <div>
                <h2>Seller Profile</h2>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <SellerSideBar />
                </div>
                <div className="col-md-9">
                  <ChatWrapper token={token} isSeller={isSeller}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <ChatWrapper token={token} isSeller={isSeller} />
    </>
  );
};

export default Chat;
