import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import App from "./App";
import SellerApp from "./SellerApp";

function AppManager() {
  const sellerIsLogin = useSelector((state) => state.SellerReducer.sellerIsLogin);

  if (sellerIsLogin) {
    return <SellerApp />;
  }
  return <App />;
}

export default AppManager;
