import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SelectedProductReviews } from "../../../network/Network";
import { ProductDetails } from "../../../network/SellerNetwork";
import { ProductAllReviews } from "../../../redux/actions/CartActions";
// import "../../assets/css/message.css";
import AllReviews from "../../components/ReviewCard/AllReviews";
import SellerHeader from "../../components/SellerHeader";
import SellerSideBar from "./SellerSideBar";

const Reviews = () => {
  const dispatch = useDispatch();
  let { product, page } = useParams();
  const [productReview, setProductReview] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(page ?? 1);
  const [paramData, setParamData] = useState(null);
  const sellerToken = useSelector((state) => state.SellerReducer.sellerToken);

  const handlePageClick = async (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const loadReviews = () => {
    SelectedProductReviews(product, currentPage)
      .then((res) => {
        setProductReview((prev) => {
          return [...res?.data?.data?.data];
        });
        dispatch(ProductAllReviews([...res?.data?.data?.data]));
        const total = res?.data?.data?.total;
        const limit = res?.data?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    ProductDetails(product, sellerToken)
      .then((res) => {
        console.log(res);
        setParamData(res?.data?.data?.product);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [product]);

  useEffect(() => {
    loadReviews();
  }, [product, currentPage]);

  const refreshReviews = () => {
    // debugger;
    loadReviews();
  };

  return (
    <div className="sellerproddetail_page sellereditdetail">
      <SellerHeader />
      <div className="container-fluid">
        <div className="sellerproddetail_sec">
          <div className="seller_heading">
            <h2>Seller Profile</h2>
          </div>
          <div className="row">
            <div className="col-md-3">
              <SellerSideBar />
            </div>
            <div className="col-md-9">
              <AllReviews
                refreshReviews={() => {
                  refreshReviews();
                }}
                seller={true}
                productReview={productReview}
                setProductReview={setProductReview}
                ParamData={paramData}
                handlePageClick={handlePageClick}
                pageCount={pageCount}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
