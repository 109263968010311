import React, { useState } from "react";
import SellerHeader from "../../components/SellerHeader";
import "../../../assets/css/productinventory.css";
import SellerFooter from "../../components/SellerFooter";
import SellerSideBar from "./SellerSideBar";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SetChangePassword } from "../../../redux/actions/SellerAction";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const Navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.SellerReducer.sellerToken);

  return (
    <>
      <div className="seller_prodinv_page profileinfo_page">
        <SellerHeader />
        <div className="container-fluid">
          <div className="seller_prodinv_sec">
            <div>
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">
                <div className="totalcard">
                  <Formik
                    initialValues={{ password: "", confirm_password: "", old_password: "" }}
                    validationSchema={Yup.object({
                      old_password: Yup.string().required("Required"),
                      password: Yup.string().required("Required"),
                      confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setBtnLoading(true);
                      let req = await dispatch(SetChangePassword(values, token));
                      if(req) {
                          Navigate("/sellerlogin");
                      }
                      setBtnLoading(false);
                      // const posted = requestPostFetch(contactApi, values);
                    }}>
                    <Form>
                      <div className="form-head">
                        <div className="seller_head">
                          <div className="">
                            <h3>Change Password</h3>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex flex-column">
                        <div className="col-md-6">
                          <Field name="old_password">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="password" class="form-control" id="inputOldPassword" placeholder="Enter Old Password" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <Field name="password">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="password" class="form-control" id="inputPassword" placeholder="Create New Password" {...field} />
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <Field name="confirm_password">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => (
                              <div class="form-group">
                                <input type="password" class="form-control" id="inputCPassword" placeholder="Confirm Password" {...field}/>
                                {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className="button-group">
                        <button type="submit" className="sellerpbtn" disabled={btnLoading}>
                          {btnLoading ? "Loading.." : "Save"}
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

export default ChangePassword;
