import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { SelectWrapper, SwitchWrapper } from "../../../../../Form/Element";

const Variation = ({ colors, callback = () => {}, choices, attributeOptions, formValues = {} }) => {
  const { setFieldValue } = useFormikContext();
  const [isColorActive, setIsColorActive] = useState(false);
  const [customizeChoice, setCustomizeChoice] = useState([]);
  const [systemDefaultColors, setSystemDefaultColors] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [selectedOptionAttribute, setSelectedOptionAttribute] = useState([]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0 ) {
      if (formValues.attributes.length > 0) handleEditAttributes();
      // handleSystemColorSelection(formValues.colors);
      if (formValues.colors.length > 0){
        setSystemDefaultColors((prev) => {
          var findColors = colors
            .filter((x) => {
              var check = formValues.choices
                .find((m) => {
                  return m.name === "system_color";
                })
                .options.find((c) => x.id === c.value);
              return check !== undefined;
            })
            .map((ele) => {
              return {
                label: ele.name,
                value: ele.id,
                hash: ele.code,
              };
            });
          return findColors;
        });
      }
        
    }
  }, []);

  const handleEditAttributes = () => {
    var currentAttribute =
      Object.keys(formValues).length > 0 && formValues.attributes.length > 0
        ? attributeOptions
            .filter((x) => {
              return formValues.attributes.includes(x.value);
            })
            .map((ele) => {
              setSelectedOptionAttribute((prev) => {
                var selectedOptions = [];
                return [...prev, selectedOptions];
              });
              return {
                label: ele.label,
                value: ele.value,
              };
            })
        : [];

    setSelectedAttribute(currentAttribute);
  };
  const colorOption = colors.map((x) => {
    return {
      label: x.name,
      value: x.id,
      hash: x.code,
    };
  });

  /**
   * This is for toggling color active
   *
   * @param {Boolean} val
   */
  const handleColor = (val) => {
    setIsColorActive(val);
  };

  /**
   * This function returns the choices array of object with updated value
   *
   * @param {Object} choiceObject
   * @returns
   */
  const updateChoice = (choiceObject) => {
    let tempArray = [...choices];
    var findIndex = tempArray.findIndex((x) => x.name === choiceObject.name);
    if (findIndex >= 0) {
      tempArray[findIndex] = choiceObject;
    } else {
      tempArray.push(choiceObject);
    }
    return tempArray;
  };

  /**
   * This function retrieve value from select wrapper on the basis of value changed
   *
   * @param {Array} val
   */
  const handleSystemColorSelection = (val) => {
    let choiceObject = {
      name: "system_color",
      title: "system color",
      options: val,
    };
    const choicesResult = updateChoice(choiceObject);
    setFieldValue(
      "colors",
      val.map((x) => {
        return x.hash;
      })
    );
    callback(choicesResult.filter((e) => e.options.length));
  };

  /**
   * This function retrieve value from select wrapper on the basis of value changed and
   * update into customize choice
   *
   * @param {Array} val
   */
  const handleAttributes = (val) => {
    let customChoices = val.map((x) => {
      let prepareObject = {
        name: "choice_" + x.value,
        title: x.label,
        options:
          formValues?.choices?.find((m) => {
            return m.name === "choice_" + x.value;
          })?.options ?? [],
      };
      return prepareObject;
    });
    setCustomizeChoice(customChoices);
    setFieldValue(
      "attributes",
      val.map((x) => {
        return x.value;
      })
    );

    let systemChoiceIndex = choices.findIndex((x) => x.name === "system_color");
    if (systemChoiceIndex >= 0) {
      setFieldValue("choices", [choices[systemChoiceIndex], ...customChoices]);

      callback([choices[systemChoiceIndex], ...customChoices]);
    } else {
      setFieldValue("choices", customChoices);
      callback(customChoices);
    }
  };

  /**
   * This function retrieve value from select wrapper on the basis of value changed,
   * customize choices object on the basis of index and updated the options column and
   * at the end it will update the final array of object of choices
   *
   * @param {array} val
   * @param {number} attributeIndex
   */
  const handleAttributesOption = (val, attributeIndex) => {
    let newArray = [...customizeChoice];
    newArray[attributeIndex].options = val;
    setCustomizeChoice(newArray);

    const choicesResult = updateChoice(newArray[attributeIndex]);
    setFieldValue("choices", choicesResult);
    callback(choicesResult);
  };

  return (
    <div class="proddetail_sec">
      <h5>Variations</h5>
      <div className="proddetialname">
        <SwitchWrapper defaultValue={formValues.colors_active} key={"color-switch"} fieldName={"colors_active"} callBack={handleColor} label="Colors" />
        {isColorActive && <SelectWrapper form={false} defaultValue={systemDefaultColors} color={true} callBack={handleSystemColorSelection} options={colorOption} multi={true} />}
      </div>
      <div className="proddetialname">
        <h6 className="prodtitle">Attributes</h6>
        <SelectWrapper defaultValue={selectedAttribute} options={attributeOptions} form={false} callBack={handleAttributes} multi={true} />
      </div>
      {customizeChoice.map((x, index) => {
        var defaultOptions = formValues?.choices?.filter((x) => x.name !== "system_color")[index]?.options ?? [];
        // handleAttributesOption(defaultOptions, index)
        return (
          <div key={index} className="proddetialname">
            <h6 className="prodtitle">{x.title}</h6>
            <SelectWrapper
              options={[]}
              defaultValue={defaultOptions}
              create={true}
              form={false}
              callBack={(e) => {
                handleAttributesOption(e, index);
              }}
              multi={true}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Variation;
