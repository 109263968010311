import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CheckBoxWrapper, NumberWrapper, SelectWrapper, TextAreaWrapper, TextWrapper } from "../../../Form/Element";
import OrderSummary from "./OrderSummary";
import * as Yup from "yup";

function StepOne({ StepOneContinue, handleNext, userFormData, CheckOutData, loading }) {
  const countryData = useSelector((state) => state.AuthReducer.country);
  const checkoutRef = useRef(null);
  const [formInitialValue, setFormInitialValue] = useState({
    f_name: userFormData?.f_name ?? "",
    l_name: userFormData?.l_name ?? "",
    phone: userFormData?.phone ?? "",
    email: userFormData?.email ?? "",
    region: userFormData?.region ?? "0",
    city: userFormData?.city ?? "",
    street_address: userFormData?.street_address ?? "",
    save_data_for_future: userFormData.save_data_for_future
  });
  const [selectedRegion, setSelectedRegion] = useState({});


  useEffect(() => {
    setFormInitialValue((prev) => {
      return {
        f_name: userFormData?.f_name ?? "",
        l_name: userFormData?.l_name ?? "",
        phone: userFormData?.phone ?? "",
        email: userFormData?.email ?? "",
        region: userFormData?.region ?? "0",
        city: userFormData?.city ?? "",
        street_address: userFormData?.street_address ?? "",
        save_data_for_future: userFormData?.save_data_for_future ?? "",
      };
    });
    if (userFormData.region != "0" && countryData.length > 0) {
      setSelectedRegion((prev) => {
        return countryData.find((x) => x.id === userFormData.region);
      });
    }
  }, []);

  let countryOption =
    countryData.length > 0
      ? countryData.map((m) => {
          return {
            label: m.name,
            value: m.id,
          };
        })
      : [];
  let countryOptionIds =
    countryData.length > 0
      ? countryData.map((m) => {
          return m.id;
        })
      : [];



  const [req, setReq] = useState(false);
  const ReqHandler = (e) => {
    e.preventDefault();
    // if (!region) {
    //   setReq(true);
    //   return;
    // }
  };

  return (
    <>
      <h2 className="font-40 fw-600 text-center pb-5">Shipment Address</h2>
      <div className="row stepOne">
        <div className="col-md-7">
          <div className="setpper-step-container">
            <div className="addressDetail">
              <div className="head">
                <h4 className="font-20 fw-600">Enter Your Address Details</h4>
              </div>
              <Formik
                ref={checkoutRef}
                validationSchema={Yup.object({
                  f_name: Yup.string().required("first name field is required"),
                  l_name: Yup.string().required("last name field is required"),
                  phone: Yup.number().required("phone field is required"),
                  email: Yup.string().email().required("email field is required"),
                  region: Yup.number().oneOf(countryOptionIds, "You must select any one region"),
                  city: Yup.string().required("town city field is required"),
                  street_address: Yup.string().required("address field is required"),
                })}
                initialValues={formInitialValue}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  StepOneContinue(values);
                }}>
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextWrapper fieldName="f_name" defaultValue={formInitialValue.f_name} label="first name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextWrapper fieldName="l_name" defaultValue={formInitialValue.l_name} label="last name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <NumberWrapper fieldName="phone" defaultValue={formInitialValue.phone} label="phone" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextWrapper fieldName="email" defaultValue={formInitialValue.email} label={"email"} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <SelectWrapper options={countryOption} defaultValue={{ value: selectedRegion?.id ?? "", label: selectedRegion?.name ?? "" }} className="region" fieldName={"region"} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <TextWrapper fieldName="city" defaultValue={formInitialValue.city} label="Town City" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <TextAreaWrapper fieldName="street_address" defaultValue={formInitialValue.street_address} label="Your Address" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">{formInitialValue.save_data_for_future != undefined && <CheckBoxWrapper fieldName="save_data_for_future" labelText="Save your information for future" defaultValue={formInitialValue.save_data_for_future} />}</div>
                    </div>
                  </div>
                  <ul className="list-inline">
                    <li>
                      <button type="submit" className="default-btn next-step" disabled={loading}>
                        {loading ? "Loading.." : "Continue to next step"}
                      </button>
                    </li>
                  </ul>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <OrderSummary CheckOutData={CheckOutData} />
      </div>
    </>
  );
}

export default StepOne;
