import React, { useEffect, useRef, useState } from "react";
import SellerHeader from "../../components/SellerHeader";
import SellerSideBar from "./SellerSideBar";
import SellerFooter from "../../components/SellerFooter";
import "../../../assets/css/sellerproddetail.css";
import uploadimg from "../../../assets/img/uploadimg.png";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { CkEditorWrapper, ImageWrapper, SelectWrapper, SwitchWrapper, TextAreaWrapper, TextWrapper, toFormData } from "../../../Form/Element";
import Variation from "./Product/Components/Variation";
import ProductPriceSection from "./Product/Components/ProductPrice";
import { updateProduct } from "../../../redux/actions/SellerAction";
import { GetAllAttribute, ProductDetails } from "../../../network/SellerNetwork";
import { useParams } from "react-router-dom";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const fileSize = 1000000;

const checkFileSize = (element) => {
  if (element.status == "add" || element.status == "del") return false;
  return element.file.size > fileSize;
};
const checkFileType = (element) => {
  if (element.status == "add" || element.status == "del") return false;
  return !SUPPORTED_FORMATS.includes(element.file.type);
};

// export const validateImageType = (value) => {
//   if (value) {
//     let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
//     return SUPPORTED_FORMATS.includes(type);
//   }
// };

const SellerProductDetail = () => {
  const currentCategories = useSelector((state) => state.AuthReducer.categoriesData);
  let { product_id } = useParams();
  const currentColors = useSelector((state) => state.AuthReducer.colorsData);
  const currentBrands = useSelector((state) => state.AuthReducer.brands);
  const token = useSelector((state) => state.SellerReducer.sellerToken);
  const [currentAttributes, setCurrentAttributes] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});
  const [productCategory, setProductCategory] = useState({});
  const [productSubCategory, setProductSubCategory] = useState({});

  // Get All attributes
  useEffect(() => {
    if (currentAttributes !== undefined && currentAttributes.length === 0) {
      GetAllAttribute(token)
        .then((res) => {
          setCurrentAttributes(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (currentProduct !== undefined && Object.keys(currentProduct).length == 0) {
      ProductDetails(product_id, token)
        .then((res) => {
          setCurrentProduct(res?.data?.data.product);
          setProductCategory(res?.data?.data.product_category);
          setProductSubCategory(res?.data?.data.sub_product_category);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const formProps = {
    currentColors: currentColors,
    currentBrands: currentBrands,
    currentCategories: currentCategories,
    currentAttributes: currentAttributes,
    currentProduct: currentProduct,
    selectedCategory: productCategory,
    selectedSubCategory: productSubCategory,
  };

  return (
    <>
      <div className="sellerproddetail_page addnewproduct_sec">
        <SellerHeader />
        <div className="container-fluid">
          <div className="sellerproddetail_sec">
            <div className="seller_heading">
              <h2>Seller Profile</h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <SellerSideBar />
              </div>
              <div className="col-md-9">{Object.keys(currentProduct).length > 0 && Object.keys(productCategory).length > 0 && <MainForm {...formProps} />}</div>
            </div>
          </div>
        </div>
      </div>
      <SellerFooter />
    </>
  );
};

const MainForm = ({ currentProduct, currentCategories, currentBrands, currentColors, currentAttributes, selectedCategory, selectedSubCategory }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const unitMeasurement = ["kg", "pc", "gms", "ltrs"];
  const [choices, setChoices] = useState([]);
  const [isTrade, setIsTrade] = useState(false);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);
  const token = useSelector((state) => state.SellerReducer.sellerToken);

  const productRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentProduct.choice_options.length > 0) {
      setChoices(currentProduct.choice_options);
    }
  }, [currentProduct.choice_options]);

  useEffect(() => {
    if (selectedCategory.id != undefined) {
      let tempCat = currentCategories.find((x) => x.id === selectedCategory.id);

      setSubcategoryOptions((prev) => {
        return tempCat.childes.map((ele) => {
          return { label: ele.name, value: ele.id };
        });
      });
    }
  }, [selectedCategory.id]);

  const attributeOptions = currentAttributes.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const categoryOptions = currentCategories.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const unitOption = unitMeasurement.map((x) => {
    return {
      label: x,
      value: x,
    };
  });

  const brandsOption = currentBrands.map((x) => {
    return {
      label: x.name,
      value: x.id,
    };
  });

  const selectedBrand = currentBrands.find((x) => {
    return x.id == currentProduct.brand_id;
  });

  const handleSubCategories = (currentCatId) => {
    let tempCat = currentCategories.find((x) => x.id === currentCatId);

    setSubcategoryOptions((prev) => {
      return tempCat.childes.map((ele) => {
        return { label: ele.name, value: ele.id };
      });
    });
  };

  const handleImages = () => {};

  const handleTrade = (currentVal) => {
    setIsTrade(currentVal);
  };

  const handleChoices = (newChoice) => {
    setChoices(newChoice);
  };

  const categoryIds = currentCategories.map((x) => {
    return x.id;
  });

  const formInitialValue = {
    id: currentProduct.id,
    name: currentProduct.name ?? "",
    description: currentProduct.details ?? "",
    unit: currentProduct.unit ?? "",
    category_id: selectedCategory.id ?? "",
    sub_category_id: selectedSubCategory?.id ?? "",
    brand_id: currentProduct.brand_id ?? 0,
    price: currentProduct.price ?? 0,
    is_trade: currentProduct.is_trade == 1 ? true : false ?? false,
    trade_qty: currentProduct.trade_qty ?? 1,
    colors_active: currentProduct.colors.length > 0 ?? false,
    colors: currentProduct.colors ?? [],
    attributes: currentProduct.attributes ?? [],
    images:
      currentProduct.images.map((x) => {
        return { alt: x, image: x, status: "add" };
      }) ?? [],
    image: [{ alt: currentProduct.thumbnail, image: currentProduct.thumbnail, status: "add" }],
    unit_price: currentProduct.unit_price ?? 0,
    purchase_price: currentProduct.purchase_price ?? 0,
    tax: currentProduct.tax ?? 0,
    tax_type: currentProduct.tax_type ?? "",
    discount: currentProduct.discount ?? 0,
    discount_type: currentProduct.discount_type ?? "",
    current_stock: currentProduct.current_stock ?? 0,
    meta_title: currentProduct.meta_title ?? "",
    meta_description: currentProduct.meta_description ?? "",
    meta_image: currentProduct.meta_image == undefined ? [] : { alt: currentProduct.meta_image, image: currentProduct.meta_image },
    video_link: currentProduct.video_link ?? "",
    variations: currentProduct?.variation ?? [],
    choices: currentProduct?.choice_options ?? [],
  };

  return (
    <Formik
      ref={productRef}
      initialValues={formInitialValue}
      validationSchema={Yup.object({
        unit: Yup.string().oneOf(unitMeasurement, "You must select any one unit"),
        category_id: Yup.number().oneOf(categoryIds, "You must select any one category"),
        images: Yup.array()
          .test("fileSize", "File is too large", (value) => {
            return value.length > 0
              ? value.some((ele) => {
                  var testFile = !checkFileSize(ele);
                  return testFile;
                })
              : true;
          })
          .test("fileType", "File type is not support", (value) => {
            return value.length > 0
              ? value.some((val) => {
                  var testFile = !checkFileType(val);
                  return testFile;
                })
              : true;
          }),
        image: Yup.array()
          .test("fileSize", "File is too large", (value) => {
            return value.length > 0
              ? value.some((ele) => {
                  var testFile = !checkFileSize(ele);
                  return testFile;
                })
              : true;
          })
          .test("fileType", "Please upload the right file type", (value) => {
            return value.length > 0
              ? value.some((val) => {
                  var testFile = !checkFileType(val);
                  return testFile;
                })
              : true;
          }),
        tax: Yup.number().min(0, "").required(),
        unit_price: Yup.number().min(1).required().label("Unit Price"),
        purchase_price: Yup.number().min(1).required().label("Purchase Price"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setBtnLoading(true);
        // TODO: convert json with formData or try to submit data with json and files
        // TODO: use Content-Type multipart/form-data
        // TODO: care the submission of
        var { image, meta_image, images, attributes, variations, choices, colors, ...rest } = values;
        var collectImageFiles = images.filter((f) => f.status != "add" && f.status != "del").map((e) => e.file);
        var prepareFormObject = {
          ...rest,
          colors: JSON.stringify(colors),
          variations: JSON.stringify(variations),
          choices: JSON.stringify(choices),
          attributes: JSON.stringify(attributes),
        };
        var formValues = toFormData(prepareFormObject);
        collectImageFiles.forEach((image, index) => {
          formValues.append("images[" + index + "]", image);
        });
        images.forEach((image, index) => {
          if(image.status == "add"){
            formValues.append("previous_images["+index+"]", image.image)
          }
        })
        
        formValues.append("image", image[0]?.file ?? null);
        formValues.append("image_updated", image[0]?.status == undefined ?? false);
        formValues.append("meta_image", meta_image[0]?.file ?? null);
        formValues.append("meta_image_updated", meta_image[0]?.status == undefined ?? false);

        await dispatch(updateProduct(formValues, () => {}, token));
        // const posted = requestPostFetch(contactApi, values);
        // resetForm({ values: "" });
      }}>
      <Form>
        <div className="proddetail_sec">
          <div className="proddetail_head">
            <h3>Update Product</h3>
          </div>
          <div className="prodimg_sec">
            <p>Product Thumbnail Image</p>
            <ImageWrapper
              fieldName={"image"}
              path={"/thumbnail/"}
              defaultValue={formInitialValue.image}
              callBack={(e) => {
                handleImages(e);
              }}
            />
          </div>
          <div className="prodimg_sec">
            <p>Product Image</p>
            <ImageWrapper
              fieldName={"images"}
              defaultValue={formInitialValue.images}
              callBack={(e) => {
                handleImages(e);
              }}
              multi={true}
            />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Product Title</h6>
            <Field name="name">
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => (
                <div class="form-group">
                  <input type="text" class="form-control" id="prodtitle" placeholder="Enter Product Title" {...field} />
                  {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                </div>
              )}
            </Field>
          </div>
          <div className="proddetialname flex-column align-items-start justify-content-around">
            <h6 className="prodtitle">Product Description</h6>
            <CkEditorWrapper defaultValue={formInitialValue.description} fieldName={"description"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Category</h6>
            <SelectWrapper defaultValue={{ label: selectedCategory.name, value: selectedCategory.id }} options={categoryOptions} callBack={handleSubCategories} fieldName={"category_id"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Sub Category</h6>
            {Object.keys(selectedSubCategory).length > 0 ? <SelectWrapper defaultValue={{ label: selectedSubCategory?.name, value: selectedSubCategory?.id }} options={subcategoryOptions} fieldName={"sub_category_id"} /> : <SelectWrapper options={subcategoryOptions} fieldName={"sub_category_id"} />}
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Brand</h6>
            <SelectWrapper defaultValue={{ label: selectedBrand.name, value: selectedBrand.id }} options={brandsOption} fieldName={"brand_id"} />
          </div>
          <div className="proddetialname">
            <h6 className="prodtitle">Unit</h6>
            <SelectWrapper defaultValue={{ label: formInitialValue.unit, value: formInitialValue.unit }} options={unitOption} fieldName={"unit"} />
          </div>
          <div className="proddetialname">
            <SwitchWrapper defaultValue={formInitialValue.is_trade} fieldName={"is_trade"} callBack={handleTrade} label="Is Trade" />
            {isTrade && (
              <div class="w-75 d-flex flex-row justify-content-end align-items-center">
                <h6 class="w-50">Trade Qty</h6>
                <Field name="trade_qty">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <div class="form-group">
                      <input type="text" class="form-control" id="prodprice" placeholder="Enter Qty" {...field} />
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </div>
            )}
          </div>
        </div>
        {attributeOptions.length > 0 && <Variation choices={choices} formValues={formInitialValue} colors={currentColors} callback={handleChoices} attributeOptions={attributeOptions} />}
        <ProductPriceSection choices={choices} formValues={formInitialValue} selectedVariation={currentProduct?.variation} totalStock={currentProduct.current_stock} />
        <SeoSection formValues={formInitialValue} />
        <div className="button_dv">
          <button type="submit" className="btn">
            {/* {btnLoading ? "Loading.." : "Add Product"} */}
            Update Product
          </button>
        </div>
      </Form>
    </Formik>
  );
};

// This section is specifically for seo tags and input
const SeoSection = ({ formValues = {} }) => {
  const handleImages = () => {};
  return (
    <div class="proddetail_sec">
      <div className="setchanges_dv">
        <h5>Seo Section</h5>
        <div className="proddetialname">
          <h6 className="prodtitle">Meta Title</h6>
          <div class="form-group">
            <TextWrapper defaultValue={formValues.meta_title} fieldName={"meta_title"} label={"meta title"} />
          </div>
        </div>
        <div className="proddetialname">
          <h6 className="prodtitle">Meta Description</h6>
          <TextAreaWrapper defaultValue={formValues.meta_description} fieldName={"meta_description"} label={"meta description"} />
        </div>
        <div className="prodimg_sec">
          <p>Meta Image</p>
          <ImageWrapper
            defaultValue={formValues.meta_image}
            fieldName={"meta_image"}
            callBack={(e) => {
              handleImages(e);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SellerProductDetail;
