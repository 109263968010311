import React, { useEffect, useState } from "react";
import "../../assets/css/stepform.css";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import $ from "jquery";
import StepForm from "../components/StepForm/StepForm";
import { useSelector } from "react-redux";
import { verifyCouponApplicable } from "../../Utils/coupon";

const Checkout = () => {
  const CartData = useSelector((state) => state.CartReducer.cartData);
  const couponDetail = useSelector((state) => state.CartReducer.coupon) ?? null;
  const [subTotal, setSubTotal] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(null);

  useEffect(() => {
    if (couponDetail != null) {
      let checkCoupon = verifyCouponApplicable(couponDetail, subTotal);
      if (checkCoupon.status) {
        setCouponDiscount(checkCoupon.discount);
      }
    }
  }, [couponDetail, subTotal]);

  useEffect(() => {
    TotalAmount();
  }, [CartData]);


  const TotalAmount = () => {
    let i;
    let total = 0;
    for (i = 0; i < CartData.length; i++) {
      total = total + CartData[i]?.price * CartData[i]?.quantity;
    }
    setSubTotal(total);
  };

  const CheckOutData = {
    subTotal: Number(parseFloat(subTotal)).toFixed(3),
    total: couponDiscount != null ? Number(parseFloat(subTotal)).toFixed(3) - Number(parseFloat(couponDiscount)).toFixed(3) : Number(subTotal).toFixed(2),
    CartData: CartData,
    couponData: couponDetail,
  };
  return (
    <div className="CHECKOUT_PAGE">
      <Header />
      <div className="container">
        <div className="card-stepForm">
          <StepForm CheckOutData={CheckOutData} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Checkout;
